import React, { Component } from "react";
import { importData, getTenants } from "../services/import"
import { distributorAction } from "../services/distributor"
import BaseQuestion from "./base-question";

// TODO Verificar para passar para o Backend
export default class StudentList extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      tenants: [], 
      distributors: [], 
      Import:[]
    }
  }

  async componentDidMount() {
    const distributors = await distributorAction("GET")
    this.setState({ distributors })
  }
  
  onChange = (e) => {
    e.preventDefault()
    this.setState({ [e.target.name]:e.target.value })
  }

  fetchTenants = async (e) => {
    const distributor_name = e.target.value;
    if (distributor_name === 'Conexia') {
      const tenants = await getTenants();
      this.setState({ tenants });
    }
  }

  async import() {
    const { tenants, distributors, tenant, distributor } = this.state
      try {
        const distributor_id = distributors.find(f=> f.name === distributor)._id
        const tenant_id = tenant && tenants && tenants.find(f=> f.name === tenant).id
        const import_result = await importData(distributor_id, tenant_id)   
      } catch (err) {
        console.error(err)
      }     
  }

  render() {
    return (
      <BaseQuestion name="Import" color="card badge-company">
        <div className="row admin">                                               
          <div className="col-sm-9">
            <div className="form-group m-t-25 ">
              <label>Distributor: </label>
              <select ref="user" name="distributor" className="form-control" value={this.state.distributor} onChange={(e) => { this.onChange(e); this.fetchTenants(e); }} >
                <option value="">All</option>
                {this.state.distributors.map((distributor, key) => {                                                        
                  return (<option key={key} value={distributor.name}>{distributor.name}</option>)
                })}
              </select>                                                                                                                                                    
            </div>
          </div>
          <div className="col-sm-3 mt-50">
            <button type="button" className="btn btn-warning" onClick={() => { this.import(); }}> Import </button>
          </div> 
        </div>
        {this.state.distributor && this.state.tenants.length > 0 && (
          <div className="row">
            <div className="col-sm-9">
              <div className="form-group m-t-25 ">
                <label>Tenant: </label>
                <select ref="tenant" name="tenant" className="form-control" value={this.state.tenant} onChange={this.onChange} >
                    <option value="">All</option>
                    {this.state.tenants.map((tenant, key) => {                                                        
                      return (<option key={key} value={tenant.name}>{tenant.name}</option>)
                    })}
                </select>                                                                                                                                                    
              </div>
            </div>
          </div>
        )}
        {/* <div className="table-responsive b-0">
            <div className="col ">
                <table className="table table-hover mb-0">
                <thead>
                    <tr>
                        <th>TenantName</th>
                        <th>Distributor</th>
                    </tr>
                </thead>
                <tbody> 
                      {this.state.Import.map((item,key) => {
                        return(
                          <tr key={key}>
                            <td>{item.information.tenantName}</td>
                            <td>{item.distributor}</td>
                          </tr>
                        )
                      })}                                     
                </tbody>  
                </table>
            </div>
        </div>  */}
      </BaseQuestion>                 
    );
  }
}
