import axios from 'axios'
import { extractSlug } from './services/slug'

const { 
  BACKEND_URL,
  S3_BUCKET,
  S3_REGION,
  S3_ACCESS_KEY,
  S3_SECRET_ACCESS,
  CONEXIA_CLIENT_ID,
  CONEXIA_CLIENT_SECRET
  // eslint-disable-next-line no-undef
} = process.env

const apiURL = () => `${BACKEND_URL}/`

const Api = axios.create({
    baseURL: apiURL(),
    timeout: 30000,
    crossDomain: true,
    withCredentials: true,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Allow-Control-Allow-Origin': '*',
      'X-Requested-With': 'XMLHttpRequest',
      'Access-Control-Allow-Credentials': true,
    }
})

Api.defaults.withCredentials = true

Api.interceptors.request.use(config => {
  const slug = extractSlug()
  config.headers.slug = slug
  return config
})

const conexiaAPI = () => {
  return {
    clientID: CONEXIA_CLIENT_ID,
    clientSecret: CONEXIA_CLIENT_SECRET,
    token: "https://api.hub.conexia.com.br/prod/sso/v2/applicationlogin",
    tenant: "https://api.hub.conexia.com.br/prod/sync/v2/tenants",
    full: "https://api.hub.conexia.com.br/prod/sync/v2/full",
    status: "https://api.hub.conexia.com.br/prod/sync/v2/status"
  }
} 

const adminConfig = (dirName, param) => {
  const root = `https://${S3_BUCKET}.s3.amazonaws.com`
  const configAdmin = { 
    root, 
    param,
    config: {
      bucketName: S3_BUCKET,
      region: S3_REGION,
      accessKeyId: S3_ACCESS_KEY,
      secretAccessKey: S3_SECRET_ACCESS,
      s3Url: root,
      dirName
    }       
  }
  return configAdmin
}

export {
  apiURL,
  adminConfig,
  conexiaAPI,
  Api
 }