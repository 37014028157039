import React, { Component } from 'react'
import moment from 'moment'
import ReactPaginate from 'react-paginate'
import queryString from 'query-string'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown, faStamp, faEye, faEdit, faSave } from '@fortawesome/free-solid-svg-icons'
import { adminConfig, apiURL, Api as axios } from '../../api'
import { getUserSession } from '../../services/isLoggedIn'
import { schoolAction } from '../../services/school'
import { classroomAction } from '../../services/classroom'
import { DateRange } from 'react-date-range'
import { load } from '../../components/load'
import BaseQuestion from '../../components/base-question'
import { reportAction } from '../../services/report'
import ModalUpdateAnswer from '../../components/ModalUpdateAnswer'
import ModalConfirm from '../../components/ModalConfirm'
import EditButton from '../../components/EditButton'

import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import '../../components/date-range.css'

const API_URL = apiURL()
export default class Report extends Component {
  constructor(props) {
    super(props)
    this.state = {
      levels: ['All', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
      schools: [],
      classrooms:[],
      school: 'All',
      student: '',
      level: 'All',
      classroom: 'All',
      orderBy: 'date',
      sortBy: 'DESC',
      grid: { count: 0, data: [] },
      detail: { testByAbility: [] },
      url: adminConfig().root,
      currentPage: 0,
      finalGradeSelection: null,
      classroomSelection: null,
      user: {},
      date: {
        startDate: new Date(moment().subtract(3, 'month')),
        endDate: new Date(moment().add(1, 'month')),
        key: 'selection',
      },
      modal: {
        show: false,
        question: null,
        test: null,
        testId: null,
        onClose: () => {
          this.setState({ 
            modal: { 
              show: false,
              question: null,
              test: null,
            } 
          })
        }
      },
      modalClassroom: false
    }
  }

  componentDidMount() {
    this.loadData()
  }

  loadUser = () => {
    return getUserSession('userAdmin')
  }

  fetchData = async () => {
    load()
    const { 
      school, 
      classroom, 
      level, 
      student, 
      currentPage, 
      orderBy, 
      sortBy, 
      date, 
      user 
    }  = this.state
    const qs = {
      currentPage,
      school: (!school || school === '') ? 'All' : school,
      classroom: (!classroom || classroom === '') ? 'All' : classroom,
      student: (!student || student === '') ? 'All' : student,
      level: (!level || level === '') ? 'All' : level,
      date: date?.startDate.toISOString().split('T')[0] + '@' + date?.endDate.toISOString().split('T')[0],
      orderBy,
      sortBy
    }
    
    const grid = await reportAction('GETREPORTPROFILE', `?${queryString.stringify(qs)}`, { 
      user: { 
        profile: user.profile, 
        school: { _id: user?.school?._id }, 
        _id: user._id
      } 
    })
    this.setState({ grid }, () => load())
  }
  
  onPageClick = async (data) => {
    const { selected: currentPage } = data
    this.setState({ currentPage: currentPage }, ()=> this.fetchData())
  }

  onSortClick = async (orderBy, sortBy) => this.setState({ orderBy, sortBy }, () => this.filterGrid())

  filterGrid = () => this.onPageClick({ selected: 0 })

  loadData = async () => {
    const user = this.loadUser()
    const schools = await schoolAction('GETCOMBO')
    const classrooms = []
  
    this.setState({
      user,
      schools,
      classrooms, 
      classroomsDataset: [], 
      finalGradeSelection: null,
      classroomSelection: null,
    }, () => this.fetchData())    
  }

  onChangeSchool = async (e) => {
    e.preventDefault()
    const { name, value } = e.target
    const classrooms = (value === 'All') ? [] : await classroomAction('GETBYSCHOOL', value)
    const classroom = (value === 'All') ? null : this.state.classroom

    this.setState({ classrooms, classroom, [name]: value })
  }

  fetchClassroomBySchool = async (schoolId, callback) => {
    const classroomsDataset = await classroomAction('GETBYSCHOOL', schoolId)
    this.setState({ classroomsDataset }, callback)
  }

  onChangeClassroom = async(e) => this.onChange(e)

  onChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target
    this.setState({ [name]: value })   
  }

  playAudio = (path) => {
    const audio = new Audio(path)
    audio.play()
  }

  renderSortAndOrder = (field) => {
    return (
      <div style={{ display: 'inline-flex', alignItems: 'center', justifySelf: 'center' }}>
        <FontAwesomeIcon 
          style={{ cursor: 'pointer' }}
          icon={faChevronUp}
          onClick={() => this.onSortClick(field, 'ASC')} 
        />
        <FontAwesomeIcon 
          style={{ cursor: 'pointer' }}
          icon={faChevronDown}
          onClick={() => this.onSortClick(field, 'DESC')} 
        />
      </div>
    )
  }

  certificatePDF = (testID) => {
    window.open(`${API_URL}certificates/${testID}`)
  }

  showModal = (testId, test, question) => {
    const { modal } = this.state
    this.setState({ modal: { ...modal, show: true, question, testId, test }})
  }

  recalculate = async (testID, testType) => {
    await axios.patch('report/checkUp/' + testID + '/' + testType)
    // this.getDetail("detail_" + testID, testID)
    // this.filterGrid()
  }

  getDetail = async (id, testID) => { 
    load()
    
    const divSet = document.getElementsByClassName('set')
    const div = document.getElementById(id)
    if(!div) {
      load()
      return
    }
  
    if(divSet[0] !== undefined && divSet[0] !== div) divSet[0].style.display = 'none'
    
    if(div.style.display === 'block') {
      div.className = 'row'
      div.style.display = 'none'
      load()
      return
    }
    
    div.className = 'row set'
    div.style.display = 'block'
          
    const response = await axios.get('report/getReportDetail/' + testID)
    if (!response?.data) return
    this.setState({ detail: response.data?.test }, () => load())
  }

  updateFinalGradeByTest = async (testID) => {
    const div = document.getElementById(`finalGrade_${testID}`)
    if (!div) return

    load()
    await axios.patch('report/finalGrade/' + testID, {
      finalGrade: div?.value || 'C2'
    })
    this.setState({ finalGradeSelection: null }, () => {
      load()
      this.fetchData()
    })
  }

  updateClassroomByTest = async (testID, checkUser = false) => {
    const div = document.getElementById(`classroom_${testID}`)
    if (!div) return

    load()
    await axios.patch('report/classroom/' + testID, {
      classroom: div?.value,
      checkUser
    })
    this.setState({ classroomSelection: null, modalClassroom: false }, () => {
      load()
      this.fetchData()
    })
  }

  onDate = ({ selection: date }) => this.setState({ date })

  render() {
    const { 
      schools,
      classrooms,
      classroomsDataset,
      levels,
      school,
      student,
      classroom,
      level,
      grid,
      url,
      detail,
      modal,
      finalGradeSelection,
      classroomSelection,
      modalClassroom,
      date,
      user
    } = this.state

    const currentPage = grid?.currentPage || 0
    const gridDataLength = grid?.limit || 0
    const initialRecord = (currentPage * gridDataLength) + 1
    const finalRecord = initialRecord - 1 + (grid.data.length)

    return (
      <BaseQuestion name="Report" color="card badge-company">          

        <ModalUpdateAnswer
          show={modal.show}
          data={modal.question}
          test={modal.test}
          testId={modal.testId}
          onClose={modal.onClose}
          onLoadData={() => {
            this.getDetail("detail_" + modal.testId, modal.testId)
            this.filterGrid()
          }}
        />

        {/* filters */}
        <div className="row">
          <div className="col-sm">
            <div className="form-group">
              <label>School </label>
                <select ref="user" name="school" required className="form-control" value={school} onChange={this.onChangeSchool} >
                  <option value="All" key="school-all">All</option>
                  {schools.map((school, key) => (
                    <option key={`school-${key}`} value={school._id}>{school.name}</option>
                  ))}
                </select>
            </div>
          </div>
          <div className="col-sm">
            <div className="form-group">
              <label>Classroom </label>
              <select ref="userInput" name="classroom" required className="form-control" value={classroom} onChange={this.onChangeClassroom}>
                <option value="All" key="classroom-all">All</option>
                {classrooms.map((classroom, key) => (
                  <option key={`classroom-${key}`} value={classroom._id}> {classroom.name} </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm">
            <div className="form-group">
              <label>Student</label>
              
              <input type="search" ref="userInput"  name="student" className="form-control" value={student} onChange={this.onChange} />
            </div>
          </div>                                                  
          <div className="col-sm">
            <div className="form-group">
              <label>Final Grade </label>
              <select ref="userInput" name="level" required className="form-control" value={level} onChange={this.onChange}>
                {levels.map((level, key) => (<option key={`level-${key}`} value={level}> {level} </option>))}
              </select>
            </div>
          </div>                          
          <div className="col-sm">
            <div className="form-group">
              <label>Date Range </label>
              <DateRange
                ranges={[date]}
                onChange={this.onDate}
              />
            </div>
          </div>                          
          <div className="col-sm">
            <div className="form-group m-t-28">
              <a className="btn btn-secondary waves-effect waves-light" href="#" onClick={() => this.filterGrid()}>Search </a>
            </div>
          </div>
        </div>
        <hr />
        {/* grid */}
        <div>
          {/* header */}
          <div className="row txt-black mt-3 mb-3">
            <div className="col-sm">
              Date {this.renderSortAndOrder('date')}
            </div>
            <div className="col-sm">
              School {this.renderSortAndOrder('school')}
            </div>
            <div className="col-sm">
              Classroom {this.renderSortAndOrder('classroom.name')}
            </div>
            <div className="col-sm">
              Student {this.renderSortAndOrder('name')}
            </div>
            <div className="col-sm">
              Listening {this.renderSortAndOrder('listening.level')}
            </div>
            <div className="col-sm">
              Grammar {this.renderSortAndOrder('grammar.level')}
            </div>
            <div className="col-sm">
              Reading {this.renderSortAndOrder('reading.level')}
            </div>
            <div className="col-sm">
              Writing {this.renderSortAndOrder('writing.level')}
            </div>
            <div className="col-sm">
              Speaking {this.renderSortAndOrder('speaking.level')}
            </div>
            <div className="col-sm">
              Grade {this.renderSortAndOrder('finalGrade')}
            </div>
            <div className="col-sm"></div>
          </div>
          {/* body */}                          
          <hr />
          {grid?.data.map((item, key)=>(
            <React.Fragment key={`grid-${key}`}>
              <div className={`row ${(user.profile.indexOf('ADMIN') > -1 && item.hasEdited === true) ? 'text-warning' : ''}`}>                              
                <div className="griddash col-sm"> {moment(item?.date).format("l")} </div>
                <div className="griddash col-sm"> {item?.school?.name || '--'} </div>
                <div className="griddash col-sm"> 
                  {classroomSelection !== item?.testID ? (
                    <div>
                      <EditButton
                        onClick={() => {                          
                          this.fetchClassroomBySchool(item?.school?._id, () => {
                            this.setState({ 'classroomSelection': item?.testID })
                          })
                        }}
                        data={item?.classroom?.name || '--'}
                      />
                    </div>

                  ) : (
                    <div className="row">
                      <div className="col-8 m-0 p-0">
                        <select
                          required 
                          className="form-control" 
                          defaultValue={item?.classroom?._id}
                          id={`classroom_${item?.testID}`}
                          >
                          {classroomsDataset.map((classroom) => (
                            <option
                              key={`classroom${item?.testID}-selection-${classroom._id}`}
                              value={classroom._id}
                            >
                              {classroom.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-4 m-0 p-0">
                        <ModalConfirm
                          show={modalClassroom}
                          title={`Update student`}
                          description={`Do you want the change to be permanent too, that is, for the student to be removed from the current class and placed in the new class?`}
                          onNo={() => this.updateClassroomByTest(item?.testID)}
                          onYes={() => this.updateClassroomByTest(item?.testID, true)}
                          onCancel={() => this.setState({ modalClassroom: false })}
                        />
                        <button
                          className="btn btn-link"
                          style={{ boxShadow: 'none' }}
                          onClick={() => this.setState({ modalClassroom: true })}
                        >
                          <FontAwesomeIcon icon={faSave} color="#188dca" />
                        </button>
                      </div>
                    </div>
                  )}                  
                </div>
                <div className="griddash col-sm"> {item?.user?.name} </div>
                <div className="griddash col-sm"> {item?.listening.level}</div> 
                {/* : {item?.listening.weight} %  */}
                <div className="griddash col-sm"> {item?.grammar.level} </div>
                <div className="griddash col-sm"> {item?.reading.level} </div>
                <div className="griddash col-sm"> {item?.writing.level} </div>
                <div className="griddash col-sm"> {item?.speaking.level} </div>
                <div className="griddash col-sm"> 
                  {finalGradeSelection !== item?.testID ? (
                    <div>
                      <EditButton
                        onClick={() => this.setState({ 'finalGradeSelection': item?.testID })}
                        data={item?.finalGrade}
                      />
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-8 m-0 p-0">
                        <select
                          required 
                          className="form-control" 
                          defaultValue={item?.finalGrade}
                          id={`finalGrade_${item?.testID}`}
                          >
                          {levels.map((level, key) => level !== 'All' && (
                            <option
                              key={`level${item?.testID}-selection-${key}`}
                              value={level}
                            >
                              {level}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-4 m-0 p-0">
                        <button
                          className="btn btn-link"
                          style={{ boxShadow: 'none' }}
                          onClick={() => this.updateFinalGradeByTest(item?.testID)}
                        >
                          <FontAwesomeIcon icon={faSave} color="#188dca" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="griddash col-sm">
                  <button
                    className="btn btn-sm"
                    style={{ marginRight: 10 }}
                    onClick={() => this.certificatePDF(item?.testID)}
                  >
                    <FontAwesomeIcon icon={faStamp} />
                  </button>

                  <button
                    className="btn btn-primary btn-sm"
                    onClick={() => this.getDetail("detail_" + item?.testID, item?.testID)}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </button>
                </div>
              </div>
              <hr />
                                          
              {/* detail */}
              <div id={"detail_" + item?.testID}  className="row none">                                 
                {detail && (
                  <div>
                    {detail.testByAbility.map((test, index)=>(
                      <div key={`testBuAbility-${index}`}>
                        <div className="text-center font-18 bold m-t-20 "> {test.testType} {test.level} { ((test.detail_results.correct_count / test.detail_results.count) * 100.00).toFixed(2) } % </div>
                          <div className="d-flex text-center justify-content-center m-b-20">
                            <div className="mt-2 mr-4"> Total Hits
                              <div className="text-green bold font-20"> {test.detail_results.correct_count} </div>
                            </div>
                            <div className="mt-2 ml-4 "> Total Mistakes
                              <div className="text-red bold font-20"> {test.detail_results.mistakes_count} </div>
                            </div>
                            <div className="mt-2 ml-4 position-absolute" style={{ right: 20 }}>
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() => this.recalculate(item?.testID, test?.testType)}
                              >
                                  Check Up
                              </button>
                            </div>
                          </div>
                          <div className="row ml-2 m-0 f-5 justify-content-center">
                            <div className="tab linha dasht col-sm-1">Level Order</div>
                            <div className="tab linha dasht col-sm">Grammar</div>
                            <div className="tab linha dasht col-sm">Sentence</div>
                            <div className="tab linha dasht col-sm">Correct Answer</div>
                            <div className="tab linha dasht col-sm">Student Answer</div>
                            <div className="tab linha ma100 dasht br col-sm-2">Status</div>
                            <div className="tab linha ma100 dasht br col-sm-2">Actions</div>
                          </div>
                        
                          {test.questions.map((question, key) => (                                  
                            <div key={`question-test-item-${key}`} className="row ml-2 m-0 f-5 justify-content-center ">
                              <div className="tab linha col-sm-1 d-flex align-items-center">Level: {question.level} - {question.order} </div>
                              <div className="tab linha col-sm d-flex align-items-center">{question.grammar}</div>
                              
                              {/* sentence */}
                              <div className="tab linha col-sm d-flex align-items-center"> 
                                {question.modelname === "Listening-A"  &&
                                  <div onClick={() => { this.playAudio(url + "/la/" + question.sentence) }}>
                                    <img src="../../img/play_arrow.png"/>
                                  </div>
                                }
                                {question.modelname === "Listening-B" &&                                                
                                  <div onClick={() => { this.playAudio(url + "/lb/" + question.sentence) }}> 
                                    <img src="../../img/play_arrow.png"/>
                                  </div>
                                }
                                {question.modelname === "Writing-A" &&                                                                                                                                                 
                                  <div onClick={() => { this.playAudio(url + "/wr/" + question.sentence) }}>
                                    <img src="../../img/play_arrow.png"/>
                                  </div>
                                }
                                {question.modelname === "Speaking-A" &&                                                                                                
                                  <div onClick={() => { this.playAudio(url + "/sa/" + question.sentence) }}>
                                    <img src="../../img/play_arrow.png"/>
                                  </div>
                                }
                                  {question.modelname === "Writing-B" &&                                                                                                
                                  <div>{question.choices?.[0].word} </div>
                                }
                                <span className="m-1">{question.modelname !== "Writing-B" && <span>{question.sentence}</span>}</span>
                              </div>
                              
                              {/* answer */}
                              <div className="tab linha col-sm d-flex align-items-center">
                                {question.model === "Listening" && 
                                  <div className="d-flex">
                                    {question.modelname === "Listening-A" &&
                                      <div>
                                        <img width="70"  src={url + "/la/" + question.choices?.[0].word} controls="controls" />
                                      </div>
                                    }
                                    <span className="ml-1 align-self-center"> {question.choices?.[0].word}</span>
                                  </div>
                                }                        
                                {question.model === "Reading" && 
                                  <div className="d-flex">
                                    {question.modelname === "Reading-A"  &&
                                    <div>
                                      <img width="70" src={url + "/ra/" + question.choices?.[0].word} controls="controls" />
                                    </div>
                                    }
                                    <span className=" ml-1 align-self-center"> {question.choices?.[0].word}</span>
                                  </div>                          
                                }                      
                                {question.model === "Grammar"  && 
                                  <div>{question.choices?.map((item, key) => (
                                    <span className="mr-1" key={`question-grammar-choice-word-${key}`}>{item.word}.</span>))}
                                  </div>
                                }                         
                                {question.modelname === "Speaking-A" && <div>{question.choices?.[0].word}</div>}
                                {question.modelname === "Speaking-B" && <div>{question.sentence}</div>}
                                {question.model === "Writing" && 
                                  <div>
                                    {question.choices?.[0].optionlist.map((itemm, key)=>(
                                      <div key={`choice-question-item-${key}`}>{itemm}</div>
                                    ))}
                                  </div>
                                }
                              </div>
                              
                              {/* student answer */}
                              <div className="tab linha col-sm d-flex align-items-center">
                              {question.model === "Grammar"  && 
                                  <div>{question.choices?.map((item, key) => (
                                    <span className="mr-1" key={`question-grammar-choice-answer-${key}`}>{item.answer}.</span>))}
                                  </div>
                                }  
                                
                                { question.modelname === "Listening-A" &&
                                  <div className="mr-1">
                                    {question.answer !== 'No answer.' && (
                                      <img width="70" src={url + "/la/" + question.answer} controls="controls" />
                                    )}
                                  </div>
                                }
                                { question.modelname === "Reading-A"  &&
                                  <div className="mr-1">
                                    {question.answer !== 'No answer.' && (
                                      <img width="70" src={url + "/ra/" + question.answer} controls="controls" />
                                    )}
                                  </div>
                                } 
                                <div className="d-block">                                                
                                  { question.modelname === "Speaking-A" &&
                                    <div>                                                     
                                      {question.answer !== "No answer selected" && 
                                        <div onClick={() => { this.playAudio(url + "/audio/" + item.testID + "/" + question.id + ".mp3") }}>
                                          <img src="../../img/play_arrow.png"/>
                                          <label> &nbsp; Speech Score = {question.quality_score}</label>
                                        </div>
                                      } 
                                    </div> 
                                  }

                                  {question.modelname === "Speaking-B" &&
                                    <div>
                                      {question.audio_status!== "no audio" && 
                                        <div onClick={() => { this.playAudio(url + "/audio/" + item.testID + "/" + question.id + ".mp3") }}>
                                          <img src="../../img/play_arrow.png"/>
                                          <label> &nbsp; Speech Score =  {question.quality_score}</label>
                                        </div>
                                      } 
                                      {question.audio_status === "no audio" && 
                                        <div className="ml-1">No answer selected</div>
                                      } 
                                    </div> 
                                  }

                                  { question.modelname !== "Speaking-B" && 
                                    <span className=" ml-1 align-self-center">
                                      {question.model !== "Grammar" && <span>{question.answer}</span>}
                                    </span>
                                  }                                          
                                </div>
                              </div>                        
                              <div className="tab linha br text-center ma100 col-sm-2 d-flex align-items-center">
                                { question.iscorrect === true ? 
                                  <span className="botaobag m-auto">CORRECT</span>: 
                                  <span className="bag m-auto">INCORRECT</span>
                                }
                              </div>
                              <div className="tab linha br text-center ma100 col-sm-2 d-flex align-items-center">
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() => this.showModal(item?.testID, test, question)}
                                >
                                  <FontAwesomeIcon icon={faEdit} />
                                </button>
                              </div>
                            </div>                                    
                          ))}
                      </div>
                    ))}
                  </div>
                )} 
                <br/>                  
              </div>                            
            </React.Fragment>
          ))}

          <div id="react-paginate">
            <ReactPaginate
              pageCount={grid?.lastPage || 10}
              pageRangeDisplayed={10}
              onPageChange={this.onPageClick}
            />
            <p>Total of records: {initialRecord} - {finalRecord} / {grid?.count || 0}</p>
          </div>
        </div>
      </BaseQuestion>
    )
  }
}