/* eslint-disable import/named */
/* eslint-disable import/no-named-as-default-member */
import React, { Component } from 'react'
import { Switch } from 'react-router-dom'
import { withRouter } from 'react-router'

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import './css/font.css'
import './css/style.css'
import './css/rwd-table.min.css'
import Login from './components/login'
import Auth from './components/Auth'
import Main from './components/main'
import QuestionList from './components/questions'
import ReadingA from './components/reading-a'
import ReadingB from './components/reading-b'
import GrammarA from './components/grammar-a'
import GrammarB from './components/grammar-b'
import ListeningA from './components/listening-a'
import ListeningB from './components/listening-b'
import SpeakingA from './components/speaking-a'
import SpeakingB from './components/speaking-b'
import WritingA from './components/writing-a'
import WritingB from './components/writing-b'
import SchoolList from './components/school-list'
import School from './components/school'
import ImportUsers from './components/import-users'
import Dashboard from './pages/Dashboard'
import Report from './pages/Report'
import Test from './pages/Test'
import Finance from './pages/Finance'
import { isLoggedIn } from './services/isLoggedIn'
import ClassroomList from './components/classroom-list'
import Classroom from './components/classroom'
import DistributorList from './components/distributor-list'
import Distributor from './components/distributor'
import User from './components/user'
import UserList from './components/user-list'
import { AnimatePresence } from 'framer-motion'
import Load from './components/load'
import Routing from './components/Routing'
import Page404 from './pages/Page404'
import InvalidSlug from './pages/InvalidSlug'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loggedIn: null
    }
  }  

  componentDidMount() {  
    const loggedIn = isLoggedIn()
    this.setState({ loggedIn })
  }

  render() {  
    const { loggedIn } = this.state
    if (!loggedIn) return <Load />
  
    return (
      <>
        <AnimatePresence>
          <Switch> 
            <Routing path="/" exact component={InvalidSlug}/>
            <Routing path="/:slug" exact component={Login}/>
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/main" component={Main} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/reading-a" component={ReadingA} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/edit-reading-a/:id" component={ReadingA} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/reading-b" component={ReadingB} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/edit-reading-b/:id" component={ReadingB} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/grammar-a" component={GrammarA} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/edit-grammar-a/:id" component={GrammarA} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/grammar-b" component={GrammarB} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/edit-grammar-b/:id" component={GrammarB} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/listening-a" component={ListeningA} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/edit-listening-a/:id" component={ListeningA} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/listening-b" component={ListeningB} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/edit-listening-b/:id" component={ListeningB} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/speaking-a" component={SpeakingA} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/edit-speaking-a/:id" component={SpeakingA} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/speaking-b" component={SpeakingB} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/edit-speaking-b/:id" component={SpeakingB} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/writing-a" component={WritingA} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/edit-writing-a/:id" component={WritingA} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/writing-b" component={WritingB} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/edit-writing-b/:id" component={WritingB} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/question" component={QuestionList} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/school-list" component={SchoolList} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/school" component={School} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/edit-school/:id" component={School} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/importusers" component={ImportUsers} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/classroom-list" component={ClassroomList} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/classroom" component={Classroom} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/edit-classroom/:id" component={Classroom} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/distributor-list" component={DistributorList} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/distributor" component={Distributor} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/edit-distributor/:id" component={Distributor} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/user" component={User} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/user-list" component={UserList} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/edit-user/:id" component={User} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/dash" component={Dashboard} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/report" component={Report} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/test" component={Test} />
            <Routing loggedIn={loggedIn} isAdmin={true} path="/:slug/finance" component={Finance} />
            <Routing path="/:slug/auth" component={Auth} />


            <Routing path="*" component={Page404} />
          </Switch>
        </AnimatePresence>
      </>
    );
  }
}

export default withRouter(App);