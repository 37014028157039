import React, { Component } from 'react'
import Modal from './modal'
import { Link } from 'react-router-dom'
import { classroomAction } from '../services/classroom'
import { distributorAction } from '../services/distributor'
import { schoolAction } from '../services/school'
import { userAction } from '../services/user'
import { getUserSession } from "../services/isLoggedIn"
import BaseQuestion from "./base-question"
import { baseSlugURL, extractSlug } from '../services/slug'

const BASE_SLUG_URL = baseSlugURL()
const SLUG_URL = extractSlug()
export default class Classroom extends Component {
  constructor(props) {
  super(props)

  this.state = {
    id: '',
    period: '',
    year: '',
    name: '',
    school: '',
    distributor: '',
    show: false,
    schools: [],
    coaches: [],
    coach: '',
    classrooms: [],
    distributors: [],
    students: [],
    student: [],
    users: [],
    user: '',
  }
  }

  async componentDidMount() {    
    const user = getUserSession('userAdmin')
    const distributors = await distributorAction('GETCOMBO')
    const schools = await schoolAction('GETCOMBO')
    this.setState({ user, distributors, schools })

    if (this.state.edit !== undefined) {
      const { match: { params } } = this.props
      const classroomById = await classroomAction('GETBYID', params.id)

      const { school } = classroomById
      this.getUserBySchool(school._id)

      this.setState({
        active: classroomById.active,
        id: classroomById.id,
        importID: classroomById.importID,
        name: classroomById.name,
        period: classroomById.period,
        users: classroomById.users,
        year: classroomById.year,
        school: school._id,
        distributor: school.distributor,
      })
    } 
  }

  async getUserBySchool(schoolID) {
    const users = await userAction('GETBYSCHOOL', schoolID)
    this.setState({ 
      students: users.filter(f => f.profile === 'STUDENT'), 
      coaches: users.filter(f => f.profile === 'COACH') 
    })
  }

  onChange = (e) => {
    e.preventDefault()
    this.setState({ [e.target.name]: e.target.value })
  }

  onChangeSchool = (e) => {
    e.preventDefault()
    const { name, value } = e.target
    this.setState({ [name]: value }, () => this.getUserBySchool(value))
  }

  onChangeDistributor = async (e) => {
    e.preventDefault()
    const { name, value } = e.target
    const schools = await schoolAction('GETBYDISTRIBUTOR', value)
    this.setState({ schools, [name]: value })
  }

  addUser = (type) => {
    const { coaches, students, coach, student } = this.state
    let new_user = null
    if (type === 'COACH')
      new_user = coaches.filter(f => f.name === coach)
    else
      new_user = students.filter(f => f.name === student)
    
    let { users } = this.state
    if (users.filter(f => f.name === new_user[0].name).length === 0) {
      users = users.concat(new_user)
      this.setState({ users })
    }
  }

  deleteUser = (_id) => {
  const { users } = this.state
  this.setState({ users: users.filter(f => f._id !== _id) })
  }

  onSubmit = async (e) => {
  e.preventDefault()
  const { users } = this.state
  const { match: { params } } = this.props

  const users_id = users.map(m => { return m._id })
  const object = {
    id: this.state.id,
    importID: this.state.importID,
    name: this.state.name,
    period: this.state.period,
    school: this.state.school,
    year: this.state.year,
    distributor: this.state.distributor,
    users: users_id,
    active: true,
  }

  if (params.id === undefined) {
    await classroomAction('ADD', object)
  }
  else {
    await classroomAction('UPDATE', params.id, object)
  }

  this.setState({ show: true })
  }

  hideModal = () => this.setState({ show: false });

  render() {
    const { match: { params } } = this.props
    const { distributors, distributor, user, users } = this.state
    this.state.edit = params.id

    return (
      <BaseQuestion name="Classroom" color="card badge-company">
        <form onSubmit={this.onSubmit}>
          <div className="row">
            <div className="col-sm-8">
              <div className="form-group">
                <label>Name: </label>
                <input
                  type="text"
                  name="name"
                  required
                  className="form-control"
                  value={this.state.name}
                  onChange={this.onChange}
                  disabled={user.profile === 'COACH'}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label>Year: </label>
                <input
                  type="text"
                  name="year"
                  required
                  className="form-control"
                  value={this.state.year}
                  onChange={this.onChange}
                  disabled={user.profile === 'COACH'}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label>Distributor: </label>
                <select 
                  ref="user"
                  name="distributor" 
                  required 
                  className="form-control"
                  value={distributor} 
                  onChange={this.onChangeDistributor} 
                >
                  <option value="">Choose any</option>
                  {distributors.map((distributor, key) => (
                    <option key={key} value={distributor._id}>{distributor.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-sm">
              <div className="form-group">
                <label>School: </label>
                <select 
                  ref="user" 
                  name="school"
                  required 
                  className="form-control"
                  value={this.state.school}
                  onChange={this.onChangeSchool}
                  disabled={!['ADMIN', 'DISTRIBUTOR_ADMIN', 'MANAGER', 'SCHOOL_ADMIN'].includes(user.profile)}
                >
                  <option value="">Choose any</option>
                  {this.state.schools.map((school, key) => (
                    <option key={key} value={school._id}>{school.name} </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row m-0 ">
            <div className="col-sm mr-2 classborder d-flex">
              <div className="col-sm">
              
                <div className="form-group">
                  <label>Coach: </label>
                  <select
                    ref="user"
                    name="coach"
                    className="form-control"
                    value={this.state.coach}
                    onChange={this.onChange}
                    disabled={!['ADMIN', 'DISTRIBUTOR_ADMIN', 'MANAGER', 'SCHOOL_ADMIN'].includes(user.profile)}
                  >
                    <option value="">All</option>
                    {this.state.coaches.map((item, key) => {
                      return (<option key={key} value={item.name}>{item.name} </option>)
                    })}
                  </select>
                  <table className="mt-2 " disabled={user.profile === "COACH" ? "disabled" : "enabled"}>
                    <tbody>
                      {users.filter(f => f.profile === "COACH").map((item, key) => {
                        return (
                          <tr>
                            <td className="p1box" key={key} value={item.name}> {item.name}</td>
                            <td onClick={() => this.deleteUser(item._id)}>
                              <img className=" m-l-80 cancelred buts" alt="" src="../../img/brocoli.png" disabled={user.profile === 'COACH'} />
                            </td>
                          </tr>)
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-sm-3 m-t-27 ">
                <div 
                  className="btn waves-effect waves-light m-l-10 badge-azul" 
                  onClick={() => this.addUser('COACH')} 
                  hidden={!['ADMIN', 'DISTRIBUTOR_ADMIN', 'MANAGER', 'SCHOOL_ADMIN'].includes(user.profile)}
                > 
                  add 
                </div>
              </div>
            </div>
            <div className="col-sm ml-2 classborder d-flex">
              <div className="col-sm">
                <div className="form-group">
                  <label>Student </label>
                  <select
                    ref="user"
                    name="student"
                    className="form-control"
                    value={this.state.student}
                    onChange={this.onChange}
                    disabled={!['ADMIN', 'DISTRIBUTOR_ADMIN', 'MANAGER', 'SCHOOL_ADMIN'].includes(user.profile)}
                  >
                    <option value="">All</option>
                    {this.state.students.map((item, key) => {
                      return (<option key={key} value={item.name}>{item.name} </option>)
                    })}
                  </select>
                  <table className="mt-2 ">
                    <tbody>
                      {users.filter(f => f.profile === 'STUDENT').map((item, key) => {
                        return (
                          <tr>
                            <td className="p1box" key={key} value={item.name}> {item.name}</td>
                            <td onClick={() => this.deleteUser(item._id)}>
                              <img className=" m-l-100 cancelred buts" alt="" src="../../img/brocoli.png" disabled={user.profile === 'COACH'} />
                            </td>
                          </tr>)
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-sm-3 m-t-27">
                <div 
                  className=" btn waves-effect waves-light m-l-10 badge-azul" 
                  onClick={() => this.addUser('STUDENT')} 
                  hidden={!['ADMIN', 'DISTRIBUTOR_ADMIN', 'MANAGER', 'SCHOOL_ADMIN'].includes(user.profile)}
                >
                  add
                </div>
              </div>
            </div>
          </div>
          <div key="button">
            <div className="d-flex justify-content-center bd-highlight mb-3 m-t-80">
              <Link className="btn btn-secondary waves-effect waves-light" to={`/${SLUG_URL}/classroom-list`}> Back </Link>
              
              {['ADMIN', 'DISTRIBUTOR_ADMIN', 'MANAGER', 'SCHOOL_ADMIN'].includes(user?.profile) && (
                <input type="submit" value="Save" className="btn waves-effect waves-light m-l-10 badge-azul" />
              )}
            </div>
          </div>
        </form>
        <Modal 
          show={this.state.show} 
          hideModal={this.hideModal} 
          text="Item added successfully!" 
          redirect={`${BASE_SLUG_URL}/classroom-list`}
        />
      </BaseQuestion>
    )
  }
}