import React, { Component } from "react";
import Question from "./question";
import Modal from "./modal";
import Button from "./button";
import ImgBackground from "./img-background"
import { Api as axios, adminConfig } from "../api";
import { uploadBackground } from "../services/upload";
import BaseQuestion from "./base-question";
import { baseSlugURL } from '../services/slug'

const BASE_SLUG_URL = baseSlugURL()
export default class SpeakingB extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      order: 0,
      sentence_type: "",
      sentence: "",
      choices: [],
      values: "",
      audio: "",

      show: false,
      timer: 45,
      level: "A1",
      ptmodel: "Academic",
      modelname: "Speaking-B",
      grammar: "To be present tense",     
      file: [],
      active: true   
    };
  }


addFiles = (e) => {
    let file = this.state.file
    file = { blob: URL.createObjectURL(e.target.files[0]), 
              name: e.target.files[0].name,
              file: e.target.files[0]}
    this.setState({ file: file})
}


deleteFile = () => {
    this.setState({ file: ''})
}


componentDidMount() {
  if (this.state.edit !== undefined) {
    axios
      .get("questions/" + this.props.match.params.id)
      .then((response) => {
        
        this.setState({
          ptmodel: response.data.ptmodel,
          name: response.data.name,
          order: response.data.order,
          modelname: response.data.modelname,
          level: response.data.level,
          timer: response.data.timer,
          sentence_type: response.data.sentence_type,
          sentence: response.data.sentence,
          choices: response.data.choices,
          grammar: response.data.grammar,
          active: response.data.active,
          file: { 
            blob: adminConfig().root + "/back/sb/" + response.data.background, 
            name: response.data.background,
            file: null },
        });
      })
      .catch(function (error) {
        console.error(error);
      });
  }
}

onChangeValues = (e) => {
  this.setState({values: e})
}


onSubmit = (e) => {
  e.preventDefault();

    uploadBackground(this, "back/sb")

  const question = {
      ptmodel: this.state.ptmodel,
      name: this.state.name,
      order: this.state.order,
      modelname: this.state.modelname,
      level: this.state.level,
      timer: this.state.timer,
      sentence_type: this.state.sentence_type,
      sentence: this.state.sentence,
      choices: this.state.choices,
      grammar: this.state.grammar,
      chuncks: '',
      background: this.state.file.name,
      active: this.state.active
  };

  if (this.props.match.params.id === undefined) {
    axios.post("questions/add", question)
      .then((res) => console.log(res.data))
      .catch((error) => {
        console.error(error);
      });
  } else {
    axios.post("questions/update/" + this.props.match.params.id, question)
      .then((res) => console.log(res.data))
      .catch((error) => {
        console.error(error);
      });
  }

  this.setState({ show: true });
};

onChange = (e) => {
  if (e.target.name === 'active') {
    this.setState({ active: e.target.checked });
    return;
  }

  e.preventDefault();
  this.setState({ [e.target.name]: e.target.value });
};

updateList = (key, newlist) => {
  let choicesList = [...this.state.choices];
  let choice = { ...choicesList[key] };
  choice.optionlist = newlist;
  choicesList[key] = choice;

  this.setState({ choices: choicesList });
};

hideModal = () => {
  this.setState({ show: false });
};

render() {
  const { 
    ptmodel, 
    name, 
    order, 
    level, 
    timer, 
    modelname, 
    grammar, 
    file,
    sentence,
    sentence_type,
    values,
    show,
    active
  } = this.state

  this.state.edit = this.props.match.params.id;
    return (
      <BaseQuestion name="Speaking B" color="card badge-pink">
        <form onSubmit={this.onSubmit}>
          <Question
            ptmodel={ptmodel}
            active={active}
            name={name}
            order={order}
            level={level}
            timer={timer}
            modelname={modelname}
            grammar={grammar}
            onChange={this.onChange}
          />

          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label>Sentence: </label>
                <input
                  type="text"
                  name="sentence"
                  required
                  className="form-control"
                  value={sentence}
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label>Audio transcription: </label>
                <input
                  type="text"
                  name="sentence_type"
                  required
                  className="form-control"
                  value={sentence_type}
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>

          <ImgBackground
            src="../../img/upfilepink.png "
            classname="btn btn-outline-pink "
            file={file}
            addFiles={this.addFiles}
            deleteFile={this.deleteFile}
            values={values}
            onChangeValues={this.onChangeValues}
          />

          <Button class="badge-pink" redirect={`${BASE_SLUG_URL}/question`} />

          <Modal
            show={show}
            hideModal={this.hideModal}
            text="Item added successfully!"
            redirect={`${BASE_SLUG_URL}/question`}
          />
        </form>
      </BaseQuestion>
    );
  }
}
