import { Api as axios } from '../api'
import { getCurrentUser } from '../services/isLoggedIn'

const baseURL = `schools`

export const schoolAction = async (action, param, object) => {
  const user = getCurrentUser()
  
  switch(action) {
    case 'GET':
      const get = await axios.get(`${baseURL}/get`);      
      let school = get.data
      
      if (['COACH', 'SCHOOL_ADMIN'].includes(user.profile))     
        school = school.filter(f => f._id === user.school._id)  

      return school;
    
    case 'GETCOMBO':
      const getCombo = await axios
        .get(`${baseURL}/getCombo/${!['ADMIN', 'DISTRIBUTOR_ADMIN', 'MANAGER'].includes(user?.profile) ? user?.school?._id || '' : ''}`)
      return getCombo.data;

    case 'GETBYDISTRIBUTOR':
      const getComboDistribuidor = await axios.get(`${baseURL}/getComboDistributor/${param}`);      
      return getComboDistribuidor.data;

    case 'GETBYID': 
      const getID = await axios.get(`${baseURL}/get/${param}`);
      return getID.data;    

    case 'UPDATE':
      const getupdate = await axios.post(`${baseURL}/update/${param}`, object);
      return getupdate.data;
    
    case 'DELETE':
      const del = await axios.delete(`${baseURL}/delete/${param}`);
      return del.data;
    
    case 'ADD':
      const add = await axios.post(`${baseURL}/add`, param)
      return add.data;
    
    default:
      return;
  }
}

export const addSchool = async(school, distributor_id, import_id) => {
  const new_school = { 
    id: school.id,
    name: school.name,
    distributor: distributor_id,
    importID: import_id
  }

  return schoolAction('ADD', new_school)
}

 export const getSchool = async(id) => {
  return schoolAction('GETBYID', id)
}
