import React, { useEffect } from 'react'
import jwt_decode from 'jwt-decode'
import { baseSlugURL } from '../../services/slug'
import { setUserSession } from '../../services/isLoggedIn'
const SLUG_URL = baseSlugURL()

function Auth({ location }) {
  useEffect(() => {
    const loadStorage = async () => {
      const { search } = location
      const params = new URLSearchParams(search)
      const token = params.get('accessToken')
      const user = jwt_decode(token)
      setUserSession('userAdmin', user)
      const redirect = `${SLUG_URL}/dash`
      setTimeout(() => { window.location.href = redirect }, 2)
      return
    }

    loadStorage()
  }, [])

  return <div></div>
}

export default Auth