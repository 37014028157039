import React from 'react';

export default function Audio ({ audio , addAudio }) {
  return (
    <div className="row" key={"ad_" + audio?.name}>
      <div className="col-md-6">
        <label> Sentence:</label>
        <div className="d-flex justify-content-between bd-highlight mb-3 ">
          <div className="d-flex">
            <label htmlFor="audio">
              <img className="up" alt="" src="../../img/uppink.png" />
            </label>
            <input 
              placeholder="Upload audio"
              className="audio form-control m-r-10" 
              htmlFor="audio" 
              value={audio?.name} 
              disabled 
            />
            <input 
              placeholder="Upload file" 
              type="file" 
              name="audio" 
              id="audio" 
              className="audio" 
              onChange={addAudio}
            />
          </div>               
        </div>
      </div>

      <div className="m-t-10 col-sm">
        <audio id="recording" controls src={audio?.blob}>
          Your browser does not support the <code>audio</code> element.
        </audio>
      </div>
    </div>
  );
}
