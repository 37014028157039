import { Api as axios } from '../api'

const baseURL = `report`

export const reportAction = async(action, param, object) => {

  switch(action) {
    case 'CHECKUP': {
      const reportCheckUp = await axios.post(`${baseURL}/check-up/${param}`, object)     
      return reportCheckUp.data
    }
    case 'GETREPORTPROFILE':
      const reportProfile = await axios.post(`${baseURL}/getReportProfile/${param}`, object)     
      return reportProfile.data
    default:
      return
  }
}

export const updateQuestion = async (testID, testType, question) => {
  return axios.post(`${baseURL}/updateQuestion/${testID}/${testType}`, { question } )
}
