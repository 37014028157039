import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { baseSlugURL, extractSlug } from '../services/slug'

const BASE_SLUG_URL = baseSlugURL()
const SLUG_URL = extractSlug()
export default class Button extends Component {
  render() {
    const { redirect, class: className } = this.props
    const redirectRoute = redirect.replace(BASE_SLUG_URL + '/', '')

    return (
      <div key="button">
        <div className="d-flex justify-content-center bd-highlight mb-3 m-t-80">
          <Link className="btn btn-secondary waves-effect waves-light" to={"/" + SLUG_URL + "/" + redirectRoute}> Back </Link>
          <input type="submit" value="Save" className={"btn waves-effect waves-light m-l-10 " + className} />
        </div>          
      </div>
    )
  }
}