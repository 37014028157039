import React, { Suspense } from 'react';
import Load from '../load';
import { Route } from 'react-router-dom';
import { baseSlugURL } from '../../services/slug';

const BASE_SLUG_URL = baseSlugURL()

const Routing = ({ component: Component, loggedIn, isAdmin = false, isAuth = false, ...rest }) => {
  if (loggedIn)  {
    const { isLoggedInAdmin, isLoggedInPT } = loggedIn

    if (isAuth && !isLoggedInPT) {
      window.location.href = `${BASE_SLUG_URL}/login`
      return <></>
    }

    if (isAdmin && !isLoggedInAdmin) {
      window.location.href = BASE_SLUG_URL
      return <></>
    }
  }

  return (
    <Route
      {...rest}
      render={routeProps => (
        <Suspense fallback={<Load display="block" />}>
          <Component {...routeProps} />
        </Suspense>
      )}
    />
  );
};

export default Routing;
