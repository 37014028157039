import React, { Component } from 'react';
import DragDrop from './dragdrop';
import Question from './question';
import Modal from "./modal";
import Button from "./button";
import BaseQuestion from "./base-question"
import { Api as axios } from '../api';
import { baseSlugURL, extractSlug } from '../services/slug'

const BASE_SLUG_URL = baseSlugURL()
const SLUG_URL = extractSlug()
export default class GrammarB extends Component {

constructor(props) {
    super(props);
   
    this.state = {
        name: '',
        order: 0,
        sentence_type: '',
        sentence: '',
        choices: [], 
        values: '',
        chuncks: [],

        show: false,                
        timer: 45,
        level: 'A1',
        ptmodel: 'Academic',
        modelname: 'Grammar-B',
        grammar: 'To be present tense',
        active: true
    }
}

componentDidMount() {   

    if(this.state.edit !== undefined) {
          axios.get('questions/'+this.props.match.params.id)
               .then(response => {                                      
                    this.setState({
                        ptmodel: response.data.ptmodel,
                        name: response.data.name,
                        order: response.data.order,
                        modelname: response.data.modelname,
                        level: response.data.level,
                        timer: response.data.timer,
                        sentence_type: response.data.sentence_type,
                        sentence: response.data.sentence,
                        choices: response.data.choices,
                        grammar: response.data.grammar,
                        chuncks: response.data.chuncks,
                        active: response.data.active
                    })
                })
               .catch(function (error) {console.error(error);})
    }
}

onSubmit = (e) => {
    e.preventDefault()
        
    const question = {
        ptmodel: this.state.ptmodel,
        name: this.state.name,
        order: this.state.order,
        modelname: this.state.modelname,
        level: this.state.level,
        timer: this.state.timer,
        sentence_type: this.state.sentence_type,
        sentence: this.state.sentence,
        choices: this.state.choices,
        grammar: this.state.grammar,
        chuncks: this.state.chuncks,
        active: this.state.active
    }

    if(this.props.match.params.id === undefined) {
      axios
        .post('questions/add', question)
        .then(res => console.info(res.data))
        .catch((error) => {console.error(error)})
    } else {
      axios
        .post('questions/update/'+this.props.match.params.id, question)
        .then(res => console.info(res.data))
        .catch((error) => {console.error(error);})
    }

    this.setState({ show: true });
}

onChange = (e) => {
    if (e.target.name === 'active') {
      this.setState({ active: e.target.checked })
      return
    }

    e.preventDefault()
    this.setState({ [e.target.name]: e.target.value })
  }

generateChuncks = (e) => {
    let sentence = this.state.sentence.trim().split(" ").filter(f => f !== "")

    let ck = []
    for(let i=0; i < sentence.length; i++) {
        ck = ck.concat({text: sentence[i], position: ''})
    }
    
    this.setState({ chuncks: ck});
    this.setState({ choices: []});
}

updateChuncks = (pos, value, del) => {
    let chuncks = this.state.chuncks
    chuncks[pos].text = value
    chuncks[del].text = ''
    this.setState({chuncks: chuncks.filter(f => f.text !== '')})
}

createList = (word, index) => {
    let chunck = document.getElementsByName('sp_ck_' + index)[0];  

    let choicesList = this.state.choices
    let chuncks = this.state.chuncks
    let exists = choicesList.filter(w => w.word === word)
    
    if(exists === '') {
        let choice = { word: word, optionlist: [word]}
        choicesList = choicesList.concat(choice) 
        chuncks[index].position = choicesList.length -1
    } else {
        choicesList = choicesList.filter(w => w.word !== word)
        chuncks[index].position = ''
    }

    this.setState({choices: choicesList});
}

addWrongAnswer = (word) => {
    let choicesList = this.state.choices
    let exists = choicesList.filter(w => w.word === word)
    
    if(exists === '') {
        let choice = { word: "", optionlist: [word]}
        choicesList = choicesList.concat(choice)        
    } else {
        choicesList = choicesList.filter(w => w.word !== word)        
    }

    this.setState({choices: choicesList});
}

deleteItem = (item, key) => {
    let newlist = this.state.choices.filter(i => i.optionlist[0] !== item )
    this.setState({choices: newlist}); 
}

hideModal = () => {
    this.setState({ show: false });
}

render() {
    const { 
        ptmodel, 
        name, 
        order, 
        level, 
        timer, 
        modelname, 
        grammar, 
        choices,
        sentence,
        chuncks,
        values,
        show,
        active
      } = this.state

    this.state.edit = this.props.match.params.id;
    return (
      <BaseQuestion name="Grammar B" color="card badge-orange">
        <form onSubmit={this.onSubmit}>
          <Question
            key="qts"
            ptmodel={ptmodel}
            active={active}
            name={name}
            order={order}
            level={level}
            timer={timer}
            modelname={modelname}
            grammar={grammar}
            onChange={this.onChange}
          />

          <DragDrop
            sentence={sentence}
            values={values}
            choices={choices}
            deleteItem={this.deleteItem}
            createList={this.createList}
            updateChuncks={this.updateChuncks}
            onChange={this.onChange}
            resetChoices={this.resetChoices}
            chuncks={chuncks}
            generateChuncks={this.generateChuncks}
            addWrongAnswer={this.addWrongAnswer}
          />

          <Button class="badge-orange" redirect={`${BASE_SLUG_URL}/question`} />

          <Modal
            show={show}
            hideModal={this.hideModal}
            text="Item added successfully!"
            redirect={`${BASE_SLUG_URL}/question`}
          />
        </form>
      </BaseQuestion>
    );
  }
}

