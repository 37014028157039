import { Api as axios } from '../api'

const baseURL = `finances`

export const financeAction = async(action, param, object) => {

  switch(action) {
    case 'GET': {
      const finance = await axios.get(`${baseURL}${param}`, object)     
      return finance.data
    }
    default:
      return
  }
}