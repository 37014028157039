import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { distributorAction } from "../services/distributor"
import load from './load'
import BaseQuestion from './base-question'
import { baseSlugURL, extractSlug } from '../services/slug'

const BASE_SLUG_URL = baseSlugURL()
const SLUG_URL = extractSlug()
export default class DistributorList extends Component {

constructor(props) {
  super(props)
  
  this.state = {
    listobj: [],   
  }   
}

async componentDidMount() {
  load()
  const distributors = await distributorAction("GET")
  this.setState({ listobj: distributors })
  load()
}

delete = async(id) => {
  await distributorAction("DELETE", id)
  this.setState({
    listobj: this.state.listobj.filter(el => el._id !== id)
  })
}

render() {
  return (
  <BaseQuestion name="Distributor" color="card badge-company">
    <div className="row">
      <div className="col m-b-20">
        <div className="float-right m-r-15">
          <Link className="btn btn-secondary waves-effect waves-light" to={`/${SLUG_URL}/distributor`}>New distributor</Link>                                                                                                
        </div>   
      </div>                    
    </div>  
    <div className="table-responsive b-0">
      <div className="col ">
        <table className="table table-hover mb-0">
        <thead>
          <tr>
            <th>Name</th>
            <th>Information</th>
            <th>Email</th>
            <th>Phone</th>
            <th></th>
          </tr>
        </thead>
        <tbody> 
          {this.state.listobj.map((obj, key) => {
            return (
            <tr key={key}>
              <td>{obj.name}</td>
              <td>{obj.contact}</td>
              <td>{obj.email} </td>
              <td>{obj.phone}</td>
              <td>
                <div className="d-flex justify-content-end">
                <Link className="btn btn-primary btn-sm" to={`/${SLUG_URL}/edit-distributor/${obj._id}`}>Edit</Link> &nbsp;
                  <a className="btn  btn-sm btnred" href="#" onClick={() => { this.delete(obj._id)}}>Delete</a>
                </div>
              </td>
            </tr>)
          })}                       
        </tbody>  
        </table>
      </div>
    </div>                                                  
  </BaseQuestion>
  )}
}