import React, { Component } from 'react'
import Modal from "./modal"
import Button from "./button"
import Logo from './logo'
import { distributorAction } from '../services/distributor'
import { schoolAction } from '../services/school'
import { adminConfig } from '../api'
import { uploadBackground } from "../services/upload"
import BaseQuestion from './base-question'
import { baseSlugURL } from '../services/slug'

const BASE_SLUG_URL = baseSlugURL()
export default class School extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      contact: '',
      email: '',
      phone: '',
      address: '',
      country: '',
      role: '', 
      id: '',
      show: false,
      file: [],
      values: '',
      distributors:[]
    }  
  }

async componentDidMount() { 
  const distributors = await distributorAction('GETCOMBO')
  this.setState({ distributors })

  if(this.state.edit !== undefined) {
    const { match } = this.props
    const school = await schoolAction('GETBYID', match.params.id)   

    this.setState({
      ...school,
      distributor: school?.distributor?.name,
      file: {
        blob: adminConfig().root + '/logo/school/' + school.logo, 
        name: school?.logo,
        file: null 
      },
    })      
  }
}

addFiles = (e) => {
  const file = { 
    blob: URL.createObjectURL(e.target.files[0]), 
    name: e.target.files[0].name,
    file: e.target.files[0]
  }

  this.setState({ file })
}

deleteFile = () => this.setState({ file: '' })

onChangeValues = (values) => this.setState({ values })   

onChange = (e) => {
  e.preventDefault()
  const { target } = e
  this.setState({ [target.name]: target.value })
}

onSubmit = async(e) => {
  e.preventDefault()
  const { file, distributors } = this.state
  const { match } = this.props

  if(file?.file) uploadBackground(this, "logo/school")

  console.log(distributors)

  const object = {
    id: this.state.id, 
    name: this.state.name,
    contact: this.state.contact,
    email: this.state.email,
    phone: this.state.phone,
    address: this.state.address,
    country: this.state.country,
    role: this.state.role,
    distributor: this.state.distributor, 
    logo: file.name
  }
      
  if(match.params.id === undefined) 
    await schoolAction('ADD', object) 
  else 
    await schoolAction('UPDATE', match.params.id, object)

  this.setState({ show: true })
}

hideModal = () => this.setState({ show: false })

render() {
  const { distributors, distributor } = this.state

  this.state.edit = this.props.match.params.id
  return (
    <BaseQuestion name="School" color="card badge-company">
      <form onSubmit={this.onSubmit}>
        <div className="row"> 
          <div className="col-md-6">
            <div className="form-group"> 
              <label>Distributor: </label>
              <select 
                ref="user" 
                name="distributor" 
                required 
                className="form-control" 
                value={distributor} 
                onChange={this.onChange}
              >
                <option value="">Choose any</option>
                {distributors.map(distributor => (
                  <option key={distributor._id} value={distributor._id}>{distributor.name}</option>
                ))}
              </select>
            </div>
          </div>    
        </div>
        <div className="row">           
          <div className="col-md-6">
            <div className="form-group"> 
              <label>ID: </label>
              <input
                type="text"
                name="id"
                className="form-control"
                value={this.state.id}
                onChange={this.onChange}
              />
            </div>
          </div> 
          <div className="col-md-6">
            <div className="form-group"> 
              <label>Name: </label>
              <input
                type="text"
                name="name"
                required
                className="form-control"
                value={this.state.name}
                onChange={this.onChange}
              />
            </div>
          </div> 
                                                   
        </div>
        <div className="row">  
          <div className="col-sm">                      
            <div className="form-group"> 
              <label>Main address: </label>
              <input
                type="text"
                name="address"
                required
                className="form-control"
                value={this.state.address}
                onChange={this.onChange}
              />
            </div>
          </div>  
          <div className="col-sm">                      
            <div className="form-group"> 
              <label>Country: </label>
              <input
                type="text"
                name="country"
                required
                className="form-control"
                value={this.state.country}
                onChange={this.onChange}
              />
            </div>
          </div>   
        </div>
        <div className="row"> 
          <div className="col-md-6">
            <div className="form-group"> 
              <label>Name of main contact: </label>
              <input 
                type="text" 
                name="contact" 
                required 
                className="form-control" 
                value={this.state.contact} 
                onChange={this.onChange} 
              />
            </div>
          </div>
          <div className="col-sm">
            <div className="form-group"> 
              <label>Role: </label>
              <input 
                type="text" 
                name="role" 
                required 
                className="form-control" 
                value={this.state.role} 
                onChange={this.onChange} 
              />
            </div>
          </div>                                                                                                      
        </div>     
        <div className="row"> 
          <div className="col-sm">
            <div className="form-group"> 
              <label>Email: </label>
              <input 
                type="text" 
                name="email" 
                required 
                placeholder="email@email.com" 
                className="form-control" 
                value={this.state.email} 
                onChange={this.onChange} 
              />
            </div>
          </div>
          <div className="col-sm">                      
            <div className="form-group"> 
              <label>Phone number: </label>
              <input 
                type="text" 
                name="phone" 
                required 
                placeholder="(22) 
                1234-0000" 
                className="form-control" 
                value={this.state.phone} 
                onChange={this.onChange} 
              />
            </div>                       
          </div>     
        </div>  
        {/* upload logo */}
        <Logo 
          file={this.state.file} 
          addFiles={this.addFiles} 
          deleteFile={this.deleteFile} 
          values={this.state.values}
          onChangeValues={this.onChangeValues}
        />
        <Button class="badge-azul" redirect={`${BASE_SLUG_URL}/school-list`} />
      </form>
      <Modal
        show={this.state.show}
        hideModal={this.hideModal}
        text="Item added successfully!" 
        redirect={`${BASE_SLUG_URL}/school-list`}
      />                  
    </BaseQuestion>    
  )}
}