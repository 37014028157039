import React, { Component } from 'react';
import ReactS3 from 'aws-s3';
import Question from './question';
import Images from './images';
import Modal from "./modal"; 
import Button from "./button";
import BaseQuestion from "./base-question"
import { adminConfig, Api as axios } from '../api';
import { baseSlugURL } from '../services/slug'

const BASE_SLUG_URL = baseSlugURL()
const S3Client = new ReactS3(adminConfig('ra').config);

export default class ReadingA extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      name: '',
      order: 0,
      sentence_type: '',
      sentence: '',
      values: '',  
      choices: [], 
      files: [],

      show: false,              
      timer: 45,
      level: 'A1',
      ptmodel: 'Academic',
      modelname: 'Reading-A',
      grammar: 'To be present tense',
      active: true,
    }
  }

  addFiles = (e) => {
    let { files } = this.state;
    for(let i=0; i < e.target.files.length; i++ ) {
      if(files.filter(f => f.name === e.target.files[i].name).length === 0){
        files.push({ 
          blob: URL.createObjectURL(e.target.files[i]), 
          name: e.target.files[i].name,
          file: e.target.files[i]
        })
      }
    }
    this.setState({ files })
  }

  deleteFile = (e) => {
    const { files } = this.state
    const newlist = files.filter(f => f.name !== e)
    this.setState({ files: newlist })
  }

  componentDidMount() {
    if(this.state.edit !== undefined) {
      axios.get('questions/' +this.props.match.params.id)
        .then(response => {   
        
            let files = response.data.choices[0]
            let newfiles = []

            for(let i=0; i < files.optionlist.length; i++ ) {
              let img = adminConfig().root + "/ra/"+ files.optionlist[i]
              newfiles.push({ 
                blob: img, 
                name: files.optionlist[i],
                file: null
              })
            }

            this.setState({
                ptmodel: response.data.ptmodel,
                name: response.data.name,
                order: response.data.order,
                modelname: response.data.modelname,
                level: response.data.level,
                timer: response.data.timer,
                sentence_type: response.data.sentence_type,
                sentence: response.data.sentence,
                choices: response.data.choices,
                grammar: response.data.grammar,
                files: newfiles,
                values: response.data.choices[0].word,
                active: response.data.active
            })
        })
        .catch(function (error) {console.error(error);})
    }
}

  onSubmit = async e => {
    e.preventDefault()

    const optionlist = []
    const { files } = this.state
    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      // eslint-disable-next-line no-continue
      if (!file.file) continue

      await S3Client.uploadFile(file.file, file.name)
      optionlist.push(file.name)
    }

    const question = {
      ptmodel: this.state.ptmodel,
      name: this.state.name,
      order: this.state.order,
      modelname: this.state.modelname,
      level: this.state.level,
      timer: this.state.timer,
      sentence_type: this.state.sentence_type,
      sentence: this.state.sentence,
      choices: [{ word: this.state.values, optionlist }] ,
      grammar: this.state.grammar,
      chuncks: '',
      active: this.state.active
    }
    
    if(this.props.match.params.id === undefined) {
      await axios.post('questions/add', question)
    } else {
      await axios.post('questions/update/'+ this.props.match.params.id, question)
    }
    this.setState({ show: true })
  }

  onChange = (e) => {
    if (e.target.name === 'active') {
      this.setState({ active: e.target.checked })
      return
    }

    e.preventDefault()
    this.setState({ [e.target.name]: e.target.value })
  }

  onChangeValues = (values) => this.setState({ values })   

  hideModal = () => this.setState({ show: false })

  render() {
    const { 
      ptmodel, 
      name, 
      order, 
      level, 
      timer, 
      modelname, 
      grammar, 
      files, 
      sentence,
      values,
      show,
      active
    } = this.state

    this.state.edit = this.props.match.params.id;
    return (
      <BaseQuestion name="Reading A" color="card badge-pink">
        <form onSubmit={this.onSubmit}>
          <Question
            ptmodel={ptmodel}
            active={active}
            name={name}
            order={order}
            level={level}
            timer={timer}
            modelname={modelname}
            grammar={grammar}
            onChange={this.onChange}
          />

          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label>Sentence: </label>
                <input
                  type="text"
                  name="sentence"
                  required
                  className="form-control"
                  value={sentence}
                  onChange={this.onChange}
                />
              </div>
            </div>
          </div>

          <Images
            files={files}
            addFiles={this.addFiles}
            deleteFile={this.deleteFile}
            values={values}
            onChangeValues={this.onChangeValues}
          />

          <Button class="badge-pink" redirect={`${BASE_SLUG_URL}/question`} />

          <Modal
            show={show}
            hideModal={this.hideModal}
            text="Item added successfully!"
            redirect={`${BASE_SLUG_URL}/question`}
          />
        </form>
      </BaseQuestion>
    );
  }
}

