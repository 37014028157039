import React, { Component } from 'react'
import '../css/estilo.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { login as loginAuth } from '../services/isLoggedIn'

export default class Login extends Component {
  constructor(props) {
    super(props) 
    this.state = {
      login: '',
      pssw: '',
      messageErrorLogin: ''
    }
  }
  
componentDidMount() {
  document.body.className = 'adback'        
}

onChange = (e) => {
  e.preventDefault()
  this.setState({ [e.target.name]: e.target.value })
}

onClick = async (e) => {
  e.preventDefault()
  const { login, pssw } = this.state
  if (loginAuth) {
  const loginResponse = await loginAuth(login, pssw)
  if (loginResponse) this.setState({messageErrorLogin: loginResponse})
  }
}
  
render() {
  const { match = { params: { slug: null } } } = this.props
  const { messageErrorLogin } = this.state
  const { slug } = match.params

  const url = 'https://production-placementest.s3.amazonaws.com/assets/img/logos'
  const logo = `${url}/logo-${slug || 'conexia'}.png`

  return (
    <div>
      <form>
        <div className="bloco">
          <div className="logo">
            <h3>
              <img src={logo} alt="logo" />
            </h3>
          </div>
          <div className="login ">
            <div className="parte1 m-b-30 text-center">
              <h6>Sign in</h6>
            </div>
            <div className="form-group">
              <label className="label_str">Username</label>
              <input type="text" name="login" placeholder="Enter username" required className="form-control" value={this.state.login} onChange={this.onChange} />
            </div>
            <div className="form-group">
              <label className="label_str">Password</label>
              <input type="password" name="pssw" placeholder="Enter password" required className="form-control m-b-20" value={this.state.pwd} onChange={this.onChange} />
            </div>
            <div className="alert alert-danger p-1 ps-2" role="alert" hidden={!messageErrorLogin}>
              {messageErrorLogin}
            </div>
            <div className="row py-2">
              <div className="col d-flex justify-content-left">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" value="" id="customControlInline"/>
                  <label className="form-check-label" htmlFor="customControlInline"> Remember me </label>
                </div>
              </div>
              <div className="col text-end">
                <a href="#!">Forgot your password?</a> {/* TODO recuperacao de senha*/}
              </div>
            </div>
            <div className="text-center">             
              <input type="submit" value="Log In" className="btn-big grad-mains" onClick={this.onClick} /> 
            </div>            
          </div>
        </div>      
      </form>       
    </div>
  )
  }
}
