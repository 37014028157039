import React, { Component } from 'react'
import moment from 'moment'
import ReactPaginate from 'react-paginate'
import queryString from 'query-string'
import { getUserSession } from '../../services/isLoggedIn'
import { schoolAction } from '../../services/school'
import { classroomAction } from '../../services/classroom'
import { DateRange } from 'react-date-range'
import { load } from '../../components/load'
import BaseQuestion from '../../components/base-question'
import { testAction } from '../../services/test'

import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import '../../components/date-range.css'

export default class Test extends Component {
  constructor(props) {
    super(props)
    this.state = {
      levels: ['All', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
      schools: [],
      classrooms:[],
      school: 'All',
      student: '',
      level: 'All',
      classroom: 'All',
      orderBy: 'createdAt',
      sortBy: 'DESC',
      grid: { count: 0, data: [] },
      currentPage: 0,
      user: {},
      createdAt: {
        startDate: new Date(moment().subtract(3, 'month')),
        endDate: new Date(moment().add(1, 'month')),
        key: 'selection',
      },
    }
  }

  componentDidMount() {
    this.loadData()
  }

  loadUser = () => {
    return getUserSession('userAdmin')
  }

  fetchData = async () => {
    load()
    const { school, classroom, level, student, currentPage, orderBy, sortBy, createdAt, user }  = this.state
    const qs = {
      currentPage,
      school: (!school || school === '') ? 'All' : school,
      classroom: (!classroom || classroom === '') ? 'All' : classroom,
      student: (!student || student === '') ? 'All' : student,
      level: (!level || level === '') ? 'All' : level,
      createdAt: createdAt?.startDate.toISOString().split('T')[0] + '@' + createdAt?.endDate.toISOString().split('T')[0],
      orderBy,
      sortBy
    }
    
    const grid = await testAction('GETTESTPROFILE', `?${queryString.stringify(qs)}`, { 
      user: { 
        profile: user.profile, 
        school: { _id: user?.school?._id }, 
        _id: user._id
      } 
    })
    this.setState({ grid }, () => load())
  }
  
  onPageClick = async (data) => {
    const { selected: currentPage } = data
    this.setState({ currentPage: currentPage }, ()=> this.fetchData())
  }

  onSortClick = async (orderBy, sortBy) => this.setState({ orderBy, sortBy }, () => this.filterGrid())

  filterGrid = () => this.onPageClick({ selected: 0 })

  loadData = async () => {
    const user = this.loadUser()
    const schools = await schoolAction('GETCOMBO')
    const classrooms = []
  
    this.setState({
      user,
      schools, 
      classrooms, 
      classroomsDataset: [], 
      finalGradeSelection: null,
      classroomSelection: null,
    }, () => this.fetchData())    
  }

  onChangeSchool = async (e) => {
    e.preventDefault()
    const { name, value } = e.target
    const classrooms = (value === 'All') ? [] : await classroomAction('GETBYSCHOOL', value)
    const classroom = (value === 'All') ? null : this.state.classroom

    this.setState({ classrooms, classroom, [name]: value })
  }

  onChangeClassroom = async(e) => this.onChange(e)

  onChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target
    this.setState({ [name]: value })   
  }

  onDate = ({ selection: createdAt }) => this.setState({ createdAt })

  render() {
    const { 
      schools,
      school,
      student,
      grid,
      createdAt
    } = this.state

    const currentPage = grid?.currentPage || 0
    const gridDataLength = grid?.limit || 0
    const initialRecord = (currentPage * gridDataLength) + 1
    const finalRecord = initialRecord - 1 + (grid.data.length)

    return (
      <BaseQuestion name="Evaluation in progress" color="card badge-company">          
        {/* filters */}
        <div className="row">
          <div className="col-sm">
            <div className="form-group">
              <label>School </label>
                <select ref="user" name="school" required className="form-control" value={school} onChange={this.onChangeSchool} >
                  <option value="All" key="school-all">All</option>
                  {schools.map((school, key) => (
                    <option key={`school-${key}`} value={school._id}>{school.name}</option>
                  ))}
                </select>
            </div>
          </div>
          {/*
          <div className="col-sm">
            <div className="form-group">
              <label>Classroom </label>
              <select ref="userInput" name="classroom" required className="form-control" value={classroom} onChange={this.onChangeClassroom}>
                <option value="All" key="classroom-all">All</option>
                {classrooms.map((classroom, key) => (
                  <option key={`classroom-${key}`} value={classroom._id}> {classroom.name} </option>
                ))}
              </select>
            </div>
          </div>
          */}
          <div className="col-sm">
            <div className="form-group">
              <label>Student</label>
              
              <input type="search" ref="userInput"  name="student" className="form-control" value={student} onChange={this.onChange} />
            </div>
          </div>                       
          <div className="col-sm">
            <div className="form-group">
              <label>Date Range </label>
              <DateRange
                ranges={[createdAt]}
                onChange={this.onDate}
              />
            </div>
          </div>                          
          <div className="col-sm">
            <div className="form-group m-t-28">
              <a className="btn btn-secondary waves-effect waves-light" href="#" onClick={() => this.filterGrid()}>Search </a>
            </div>
          </div>
        </div>
        <hr />
        {/* grid */}
        <div>
          {/* header */}
          <div className="row txt-black mt-3 mb-3">
            <div className="col-sm">
              Created At
            </div>
            <div className="col-sm">
              School
            </div>            
            <div className="col-sm">
              Student
            </div>            
            <div className="col-sm">
              Status
            </div>            
            
          </div>
          {/* body */}                          
          <hr />
          {grid?.data.map((item, key)=>(
            <React.Fragment key={`grid-${key}`}>
              <div className={`row`}>                              
                <div className="griddash col-sm"> {moment(item?.createdAt).format("l")} </div>
                <div className="griddash col-sm"> {item?.userid?.school?.name || '--'} </div>
                <div className="griddash col-sm"> {item?.userid?.name} </div>
                <div className="griddash col-sm">
                  <div className="progress">
                    <div className="progress-bar bg-info" role="progressbar" style={{ width: `${((item?.complete_questions / (item?.num_questions || 1)) * 100).toFixed(2)}%` }}
                     aria-valuenow={item?.complete_questions || 1} aria-valuemin="0" aria-valuemax={item?.num_questions}>{((item?.complete_questions / (item?.num_questions || 1)) * 100).toFixed(2)}%</div>
                  </div>
                </div>
              </div>                            
            </React.Fragment>
          ))}

          <div id="react-paginate">
            <ReactPaginate
              pageCount={grid?.lastPage || 10}
              pageRangeDisplayed={10}
              onPageChange={this.onPageClick}
            />
            <p>Total of records: {initialRecord} - {finalRecord} / {grid?.count || 0}</p>
          </div>
        </div>
      </BaseQuestion>
    )
  }
}