import React, { Component } from 'react';
import Question from './question';
import Modal from "./modal";
import Button from "./button";
import Multiplechoice from "./multiplechoice";
import BaseQuestion from "./base-question";
import ImgBackground from './img-background'
import { uploadBackground } from '../services/upload';
import { Api as axios, adminConfig } from '../api';
import { baseSlugURL } from '../services/slug'

const BASE_SLUG_URL = baseSlugURL()
export default class ReadingB extends Component {
constructor(props) {
    super(props);
   
    this.state = {
        name: '',
        order: 0,
        sentence_type: '',
        sentence: '', 
        values: '', 
        choices: [{ word: "correct answer", optionlist: [] }],
        file:[] ,
        
        show: false,                
        timer: 45,
        level: 'A1',
        ptmodel: 'Academic',
        modelname: 'Reading-B',
        grammar: 'To be present tense',
        active: true
     
    }
}

addFiles = (e) => {
    let file = this.state.file
    file = { blob: URL.createObjectURL(e.target.files[0]), 
              name: e.target.files[0].name,
              file: e.target.files[0]} 
    this.setState({ file: file})
}


deleteFile = () => {
    this.setState({ file: ""})
}


componentDidMount() {
    if(this.state.edit !== undefined) {

        axios.get('questions/'+ this.props.match.params.id)
        .then(response => {                
            this.setState({
                ptmodel: response.data.ptmodel,
                name: response.data.name,
                order: response.data.order,
                modelname: response.data.modelname,
                level: response.data.level,
                timer: response.data.timer,
                sentence_type: response.data.sentence_type,
                sentence: response.data.sentence,
                choices: response.data.choices,
                grammar: response.data.grammar,
                active: response.data.active,

                file: { 
                    blob: adminConfig().root + "/back/rb/"+ response.data.background, 
                    name: response.data.background,
                    file: null },
            })
        })
        .catch(function (error) {console.error(error);})
    }
}

onChangeValues = (e) => {
    this.setState({values: e})
}


onSubmit = (e) => {
    e.preventDefault();

    uploadBackground(this, "back/rb")

    const question = {
        ptmodel: this.state.ptmodel,
        name: this.state.name,
        order: this.state.order,
        modelname: this.state.modelname,
        level: this.state.level,
        timer: this.state.timer,
        sentence_type: this.state.sentence_type,
        sentence: this.state.sentence,
        choices: this.state.choices,
        grammar: this.state.grammar,
        chuncks: '',
        background: this.state.file.name,
        active: this.state.active
    };

    if(this.props.match.params.id === undefined) {
        axios.post('questions/add', question)
                .then(res => console.log(res.data))
                .catch((error) => {console.error(error)})
        }    else {
        axios.post('questions/update/'+ this.props.match.params.id, question)
                .then(res => console.log(res.data))
                .catch((error) => {console.error(error);})
    }
    this.setState({ show: true });
}

onChange = (e) => {
    if (e.target.name === 'active') {
      this.setState({ active: e.target.checked });
      return;
    }

    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
};

correctAnswer = (e) => {
    let choices = this.state.choices;
    choices[0].word = e;
    this.setState({ choices: choices });
};

updateList = (key, newlist) => {
    let choicesList = [...this.state.choices];
    let choice = { ...choicesList[key] };
    choice.optionlist = newlist;
    choicesList[key] = choice;

    this.setState({ choices: choicesList });
};

addItem = (item, key) => {
    let list = this.state.choices[key].optionlist.filter((i) => i !== item);
    let newlist = list.concat(item);

    this.updateList(key, newlist);
}

deleteItem = (item, key) => {
    let newlist = this.state.choices[key].optionlist.filter((i) => i !== item);
    this.updateList(key, newlist);
}

hideModal = () => {
    this.setState({ show: false });
}

render() {
    const { 
        ptmodel, 
        name, 
        order, 
        level, 
        timer, 
        modelname, 
        grammar, 
        file, 
        sentence,
        choices,
        values,
        show,
        active
      } = this.state

    this.state.edit = this.props.match.params.id;
        return (
          <BaseQuestion name="Reading B" color="card badge-azul">
            <form onSubmit={this.onSubmit}>
              <Question
                ptmodel={ptmodel}
                active={active}
                name={name}
                order={order}
                level={level}
                timer={timer}
                modelname={modelname}
                grammar={grammar}
                onChange={this.onChange}
              />

              <div className="row">
                <div className="col-sm">
                  <div className="form-group">
                    <label>Sentence: </label>
                    <input
                      type="text"
                      name="sentence"
                      required
                      className="form-control"
                      value={sentence}
                      onChange={this.onChange}
                    />
                  </div>
                </div>
              </div>

              <Multiplechoice
                values={values}
                choices={choices}
                addItem={this.addItem}
                deleteItem={this.deleteItem}
                onChange={this.onChange}
                correctAnswer={this.correctAnswer}
                bagdeclass="badge badge-azul"
                bagdeclass2="badge badgelg-azul"
                asw="blue"
                img="../../img/add3.png"
                ck="checkmark cb-azul "
              />

              <ImgBackground
                classname="btn input_chunks btn-outline"
                src="../../img/upfile2.png "
                file={file}
                addFiles={this.addFiles}
                deleteFile={this.deleteFile}
                values={values}
                onChangeValues={this.onChangeValues}
              />

              <Button
                class="badge-azul"
                redirect={`${BASE_SLUG_URL}/question`}
              />

              <Modal
                show={show}
                hideModal={this.hideModal}
                text="Item added successfully!"
                redirect={`${BASE_SLUG_URL}/question`}
              />
            </form>
          </BaseQuestion>
        );
  }
}

