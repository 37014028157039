import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { classroomAction } from '../services/classroom'
import { schoolAction } from '../services/school'
import { distributorAction } from '../services/distributor'
import { getUserSession } from '../services/isLoggedIn'
import BaseQuestion from './base-question'
import { extractSlug } from '../services/slug'

const SLUG_URL = extractSlug()
export default class ClassroomList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listobj: [],
      schools: [],            
      distributors:[],
      distributor: 'All',
      school: 'All',
      user: ''
    }
  }

  componentDidMount() {
    this.loadData()
  }

  loadData = async () => {
    const distributors = await distributorAction('GETCOMBO')
    const classroom = await classroomAction('GET')      
    const schools = await schoolAction('GETCOMBO')
    const user = getUserSession('userAdmin')
    
    this.setState({ 
      distributors, 
      listobj: classroom, 
      dataset: classroom,
      schools, 
      schoolDataset: schools,
      user
    })
  }

  onChange = (e) => {
    e.preventDefault()
    this.setState({ [e.target.name]: e.target.value })
  }

  onChangeDistributor = async(e) => {
    e.preventDefault()
    const { name, value } = e.target
    this.setState({ [name]: value }, async () => {
      const { schoolDataset } = this.state
      let schools = schoolDataset
      if(value !== 'All') {
        schools = await schoolAction('GETBYDISTRIBUTOR', value) 
      }      
      this.setState({ schools })
    })
  }

  delete = async(id) => {
    await classroomAction('DELETE', id)
    this.setState({ listobj: this.state.listobj.filter(el => el._id !== id) })
  }

  filterGrid = () => {
    const { dataset, distributor, school } = this.state
    const listobj = dataset
      .filter(l => distributor !== 'All' ? l.school?.distributor === distributor : 1 > 0)
      .filter(l => (school !== 'All' ? l.school?.name === school : 1 > 0 ))

    this.setState({ listobj })
  }

  render() {
    const { user, distributor, distributors, school, schools, listobj } = this.state
    return (
      <BaseQuestion name="Classroom" color="card badge-company">
        <div>
          <div className="row d-block">
            <div className="col-sm">
              <div className="float-right m-t-30 m-r-15">                          
                {user.profile !== "COACH" ? <Link className="btn btn-secondary waves-effect waves-light" to={`/${SLUG_URL}/classroom`}>New Classroom</Link> : "" }
              </div>
            </div>
          </div>
          <div className="row admin">
            <div className="col-sm ">
              <div className="form-group">
                <label>Distributor </label>
                  <select ref="user" name="distributor" required className="form-control" value={distributor} onChange={this.onChangeDistributor} >
                    <option value="All">All</option>
                      {distributors.map((item, key) => {
                        return ( <option key={key} value={item._id}> {item.name} </option> )
                      })}
                  </select>
              </div>
            </div>
            <div className="col-sm ">
              <div className="form-group">
                <label>School </label>
                  <select ref="user" name="school" required className="form-control" value={school} onChange={this.onChange} >
                    <option value="All">All</option>
                      {schools.map((school, key) => {
                        return ( <option key={key} value={school.name}> {school.name} </option> )
                      })}
                  </select>
              </div>
            </div>         
            <div className="col-sm">
              <div className="form-group m-t-30 ">
                <a className="btn btn-primary waves-effect waves-light" href="#" onClick={() => this.filterGrid()}> Search </a>
              </div>
            </div>
          </div>
          <div className="table-responsive b-0">
                        <div className="col ">
                          <table className="table table-hover mb-0">
                            <thead>
                              <tr>
                                <th>Classroom</th>
                                <th>Year</th>
                                <th>School</th>  
                                <th></th> 
                              </tr>
                            </thead>
                            <tbody>
                              {listobj.map((obj, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{obj.name}</td>
                                    <td>{obj.year} </td>
                                    <td>{obj.school?.name || '--'}</td>
                                    <td>
                                      <div className="d-flex justify-content-end">
                                        <Link className="btn btn-primary btn-sm" to={`/${SLUG_URL}/edit-classroom/${obj._id}`} > Edit </Link> &nbsp;
                                        {user.profile === "ADMIN" ? <a className="btn  btn-sm btnred" href="#" onClick={() => { this.delete(obj._id) }} > Delete </a> : "" }
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
        </div>
      </BaseQuestion>        
    )
  }
}
