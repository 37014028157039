import React, { Component } from 'react'
import queryString from 'query-string'
import BaseQuestion from '../../components/base-question'
import { financeAction } from '../../services/finance'
import numeral from 'numeral'
import Card from '../../components/card'

import '../../css/card.css'

export default class Finance extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      loading: true
    }
  }

  componentDidMount() {
    this.loadData()
  }

  loadData = async () => {
    const qs = {}
    const data = await financeAction('GET', `?${queryString.stringify(qs)}`)
    this.setState({ data }, () => {
      setTimeout(() => this.setState({ loading: false }), 300)      
    })
  }

  render() {
    const { data, loading } = this.state

    return (
      <BaseQuestion name="Finance" color="card badge-company">          
        <div className="row cards">
          
          <div className="col-4 mt-3" style={{ paddingLeft: 0 }}>
            <Card title="Acquired credit">
              {numeral(data?.acquired).format('0.000a')}
            </Card>
          </div>

          <div className="col-4 mt-3" style={{ paddingLeft: 0 }}>
            <Card title="Credit consumed">
              {numeral(data?.consumed).format('0.000a')}
            </Card>
          </div>

          <div className="col-4 mt-3" style={{ paddingRight: 0 }}>
            <Card title="Balance">
              {numeral(data?.balance || 0).format('0.000a')}
            </Card>
          </div>
          
        </div>
      </BaseQuestion>
    )
  }
}