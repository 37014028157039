
import LogRocket from 'logrocket'
import { userAction } from './user'
import { baseSlugURL, baseURL, extractSlug } from './slug'

const BASE_SLUG_URL = baseSlugURL()
const BASE_URL = baseURL()
const SLUG = extractSlug()

export const setUserSession = (name, value) => {
  window.sessionStorage.setItem(name, value); 
}

export const removeUserSession = (name) => {
  window.sessionStorage.removeItem(name); 
}

export const getUserSession = (origin) => {  
  const user = window.sessionStorage.getItem(origin)
  return (user) && JSON.parse(window.sessionStorage.getItem(origin))   
}

export const getCurrentUser = () => {  
  const userPT = getUserSession('userPT')
  const userAdmin = getUserSession('userAdmin')
  return userPT ? userPT : userAdmin ? userAdmin : null
}

export const isLoggedIn = () => {
  const userAdmin = getUserSession('userAdmin')
  const userPT = getUserSession('userPT')

  const isLoggedInAdmin = userAdmin ? userAdmin.isLoggedIn : false
  const isLoggedInPT  = userPT ? userPT.isLoggedIn : false
  const { pathname: currentPage } = window.location
  const isAdminPage   = currentPage.split('/').filter(f=> f === 'admin')

  return { isLoggedInAdmin, isLoggedInPT, currentPage, isAdminPage };
}

export const clearSession = (origin) => {
  removeUserSession('userAdmin')
  removeUserSession('userPT')
  
  setTimeout(() => {
    const route = (origin === 'userAdmin') ? '' : 'login'
    window.location.href = `${BASE_SLUG_URL}/${route}`
  }, 10)
}

export const authorizedLogin = async user => {
  if(!user) return 'Login failed.'
  if (typeof user === 'string') return user

  let redirect = ''
  user.isLoggedIn = true  
  if(['ADMIN', 'SCHOOL_ADMIN', 'COACH', 'DISTRIBUTOR_ADMIN', 'MANAGER'].includes(user.profile)) {           
    setUserSession('userAdmin', JSON.stringify(user))
    redirect = `${BASE_URL}/${SLUG}/dash`
  }

  LogRocket.identify(user._id, {
    name: user.name,
    email: user.email,
    subscriptionType: SLUG
  })

  setTimeout(() => { window.location.href = redirect }, 2)
  return null
}

export const login = async (login, password) => {
  const user = await userAction('GETBYLOGIN', { login, password })
  return authorizedLogin(user)
}


