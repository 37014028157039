import React, { Suspense, Component } from 'react'
import queryString from 'query-string'
import BaseQuestion from '../../components/base-question'
import { faSchool, faUserAlt, faPlay, faCheck, } from '@fortawesome/free-solid-svg-icons'
import { getUserSession } from '../../services/isLoggedIn'
import { dashAction } from '../../services/dash'
import numeral from 'numeral'
import Card from '../../components/card'
import Spinner from 'react-bootstrap/Spinner'

import '../../css/card.css'

const Ability = React.lazy(() => import('./ability'))
const Evolution = React.lazy(() => import('./evolution'))
const Skill = React.lazy(() => import('./skill'))
const Statistic = React.lazy(() => import('./statistic'))

const COLORS = ['#E10DA7', '#685AEF', '#FBAE3C', '#00CAE3', '#E74141', '#29C770']

export default class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      loading: true
    }
  }

  componentDidMount() {
    this.loadData()
  }

  loadData = async () => {
    const user = getUserSession('userAdmin')
    const userContext = { 
      user: { 
        profile: user.profile, 
        school: { _id: user?.school?._id }, 
        _id: user._id
      } 
    }

    const [statistics, evolutions, abilities_skills] = await Promise.all([
      dashAction('GET', '/statistics', userContext), 
      dashAction('GET', '/evolutions', userContext),
      dashAction('GET', '/abilities_skills', userContext)
    ])
    const { abilities, skills } = abilities_skills    
    this.setState({ data: { statistics, evolutions, abilities, skills } }, () => {
      setTimeout(() => this.setState({ loading: false }, async() => {
        const wrongs = await dashAction('GET', '/wrongs', userContext)  
        const { wrongQuestions, wrongTypes } = wrongs 
        this.setState({ data: { ...this.state.data, wrongQuestions, wrongTypes } })
      }), 300)      
    })
  }

  render() {
    const { data, loading } = this.state
    const statistics = data?.statistics || {};

    return (
      <BaseQuestion name="Dashboard" color="card badge-company">          
        <div className="row cards">
          <div className="col-4" style={{ paddingLeft: 0 }}>
            <Card title="Skills">
              <Suspense fallback={<div>Loading...</div>}>
                <Ability
                  data={data?.abilities || []} 
                  loading={loading}
                  colors={COLORS}
                />
              </Suspense>
            </Card>            
          </div>

          <div className="col-8">
            <div className="row">
              <div className="col-12 m-0 p-0">
                <Card title="Statistics">
                  <div className="d-flex">
                    {statistics?.schools && (<Statistic color="#685AEF" data={statistics?.schools} label="schools" icon={faSchool} loading={loading} />)}
                    <Statistic color="#00CAE3" data={statistics?.students} label="students" icon={faUserAlt} loading={loading} />
                    <Statistic color="#E74141" data={statistics?.evaluation_play} label="evaluation in progress" icon={faPlay} loading={loading} />
                    <Statistic color="#29C770" data={statistics?.evaluation_finished} label="evaluation finished" icon={faCheck} loading={loading} />                    
                  </div>
                </Card>            
              </div>

              <div className="col-12 p-0 mt-3">
                <Card title="Levels">
                  <div className="row d-flex" style={{ overflowX: 'scroll' }}>
                    <Skill
                      data={data?.skills || []} 
                      loading={loading}
                      colors={COLORS}
                    />
                  </div>
                </Card>                
              </div>
            </div>
          </div>

          <div className="col-6 mt-3" style={{ paddingLeft: 0 }}>
            <Card title="Wrong Questions">
              {!data?.wrongQuestions ? (
                <div className="row d-flex justify-content-center align-middle" style={{ width: '100%' }}>
                  <Spinner animation="grow" variant="info" />
                </div>
             ) : (
               <>
                <div className="row txt-black mt-3 mb-3">
                  <div className="col-1"> </div>
                  <div className="col-6">Question</div>
                  <div className="col-3">Qty</div>
                  <div className="col-2">%</div>
                </div>
                <hr />
                {(data?.wrongQuestions || []).map((item, key)=>(                
                  <div className="row" key={`grid-${key}`}>
                    <div className="griddash col-1"> {key + 1}° </div>
                    <div className="griddash col-6"> {item?.question} </div>
                    <div className="griddash col-3"> {item?.wrongs} </div>
                    <div className="griddash col-2"> {numeral(item?.percentual).format('0.00%')} </div>
                  </div>
                ))}
               </>
             )}
            </Card>
          </div>

          <div className="col-6 mt-3" style={{ paddingRight: 0 }}>
            <Card title="Wrong Grammars">
              {!data?.wrongQuestions ? (
                  <div className="row d-flex justify-content-center align-middle" style={{ width: '100%' }}>
                    <Spinner animation="grow" variant="info" />
                  </div>
              ) : (
                <>
                  <div className="row txt-black mt-3 mb-3">
                    <div className="col-1"> </div>
                    <div className="col-6">Grammar</div>
                    <div className="col-3">Qty</div>
                    <div className="col-2">%</div>
                  </div>
                  <hr />
                  {(data?.wrongTypes || []).map((item, key)=>(                
                    <div className="row" key={`grid-${key}`}>
                      <div className="griddash col-1"> {key + 1}° </div>
                      <div className="griddash col-6"> {item?.type} </div>
                      <div className="griddash col-3"> {item?.wrongs} </div>
                      <div className="griddash col-2"> {numeral(item?.percentual).format('0.00%')} </div>
                    </div>
                  ))}
                  </>
              )}
            </Card>
          </div>

          <div className="col-12 m-0 p-0 mt-3">
            <Card title="Evolution">
              <Evolution 
                data={data?.evolutions || []}
                loading={loading}
                colors={COLORS}
              />
            </Card>

          </div>
        </div>
      </BaseQuestion>
    )
  }
}