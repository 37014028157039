import { Api as axios } from '../api'

const baseURL = `dashboard`

export const dashAction = async(action, param, object) => {

  switch(action) {
    case 'GET': {
      const dash = await axios.post(`${baseURL}${param}`, object)     
      return dash.data
    }
    default:
      return
  }
}