

import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

function ModalConfirm({ title, description, show, onNo, onYes, onCancel }) {
  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-sm">{description}</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" variant="secondary" onClick={onNo}>
          No
        </Button>
        <Button type="button" variant="primary" onClick={onYes}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalConfirm