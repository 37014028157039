import { Api as axios } from '../api'
import { getUserSession } from '../services/isLoggedIn'
import queryString from 'query-string'

const url = ''
const baseURL = `${url}users`

export const userAction = async (action, param, object) => {
  switch(action) {
    case 'GET':                 
      const get = await axios.get(`${baseURL}/get`)  
      return get.data
      
    case 'GET_WITH_QUERY':
      const qs = {
        ...param,
        ...object,
      }    
      const getQuery = await axios.get(`${baseURL}/list?${queryString.stringify(qs)}`)  
      return getQuery.data

    case 'GETBYID': 
      const getID = await axios.get(`${baseURL}/get/${param}`)
      return getID.data
    
    case 'GETBYSCHOOL': 
      const getBySchool = await axios.get(`${baseURL}/getBySchool/${param}`)
      return getBySchool.data

    case 'GETPROFILE':
      const getprofile = await axios.post(`${baseURL}/getbyprofile`, param)
      const user = getUserSession('userAdmin') 
      if(['SCHOOL_ADMIN', 'COACH'].includes(user?.profile)) {
        return getprofile.data.filter(f => f.school._id === user.school._id)
      }
      return getprofile.data
        
    case 'GETBYLOGIN':
      const login = await axios.post(`${baseURL}/getlogin`, param)
      return login.data

    case 'GETBYLOGINCONEXIA':
      const userConexia = await axios.post(`${baseURL}/getconexia`, param)
      return userConexia.data
      
    case 'UPDATE':
      const getupdate = await axios
        .post(`${baseURL}/update/${param}`, object)
        .catch((error) => error.response)
      return getupdate.data
        
    case 'DELETE':
      const del = await axios.delete(`${baseURL}/delete/${param}`)
      return del.data
        
    case 'ADD':
      const add = await axios
        .post(`${baseURL}/add`, param)
        .catch((error) => error.response)
      return add.data

    default:
      return
  }
}

export const addUser = async(user, profile, distributor, school, importID) => {
  const { name, login, email, id } = user
  const newUser = {
    name,
    login,
    email,
    id,
    distributor,
    school,
    importID,
    profile,
    classroom: null,
    password: '2021'
  }
  // THIAGO
  // const user_id = await axios.post(`${baseURL}/add`, newUser)
  // return user_id.data
  return userAction('ADD', newUser)
}

export const getUser = async(id) => {
  // const user = await axios.get(`${baseURL}/get/${id}`)
  // return user.data
  return userAction('GETBYID', id)
}

