import { getUserSession } from '../services/isLoggedIn'

export const actionMenu = (div) => {
  const menu = document.getElementById(div);
  menu.style.display = menu.style.display === 'block' ? 'none' : 'block'
}

export const menuAccessControl = async () => {
  const user = await getUserSession('userAdmin')
  return user?.profile    
}