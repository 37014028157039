import React, { Component } from 'react'
import Modal from './modal'
import Button from './button'
import { distributorAction } from '../services/distributor'
import {  adminConfig } from '../api'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import BaseQuestion from './base-question'
import { baseSlugURL } from '../services/slug'
import { SketchPicker } from 'react-color'
import Avatar from './avatar'
import { uploadBackgroundFile } from '../services/upload'

const BASE_SLUG_URL = baseSlugURL()

function urlencode(str) {
  str = (str + '').toString().trim().toLowerCase()
  return encodeURIComponent(str).replace(/[^\w\s]/gi, '').replace(/20/gi, '').substring(0, 10)
}

export default class Distributor extends Component {
  constructor(props) {
    super(props)

    this.onChangeDate = this.onChangeDate.bind(this)
    this.state = {
      slug: '',
      name: '',
      contact: '',
      email: '',
      phone: '',
      address: '',
      country: '',
      date_contract: new Date(),
      role: '',
      params: {
        speaking: 90
      },
      redirectUrl: '',
      show: false,
      file: {},
      certificate: {},
      values: '',
      acquired: 0,
      backgroundColor: '#36487d'
    }
  }

  async componentDidMount() {
    if(this.state.edit !== undefined) {
      const { match } = this.props
      const { id } = match.params
      const distributor = await distributorAction('GETBYID', id)
      const url = `${adminConfig().root}/assets/img`
      this.setState({
        ...distributor,
        date_contract:new Date(distributor.date_contract), 
        file: {
          blob: `${url}/logos/${distributor.logo}`,
          name: distributor.logo,
          file: null 
        },
        certificate: {
          blob: `${url}/certificates/${distributor.certificate}`,
          name: distributor.certificate,
          file: null 
        },
      })
    }
  }

  addFiles = (field, e) => {
    const [object] = e.target.files
    const { slug } = this.state
    const data = { 
      blob: URL.createObjectURL(object),
      name: `${slug}-${object.name}`,
      file: object
    }
    this.setState({ [field]: data })
  }

  deleteFile = (e) => {
    this.setState({ file: '' })
  }

  deleteCertificate = (e) => {
    this.setState({ certificate: '' })
  }

  onChange = (e) => {
    e.preventDefault()
    this.setState({ [e.target.name]: e.target.value })
  }

  onChangeSetState = (e, data) => {
    e.preventDefault()
    this.setState(data)
  }

  onSubmit = async(e) => {
    e.preventDefault()
    const { 
      file, 
      certificate,
      slug, 
      name, 
      address, 
      contact, 
      email, 
      phone, 
      country, 
      role, 
      date_contract, 
      params,
      redirectUrl,
      acquired,
      backgroundColor,
    } = this.state

    const fileAppend = file && { ...file, name: `logo-${slug}.png` }

    if(fileAppend?.file) uploadBackgroundFile(fileAppend, 'assets/img/logos')
    if(certificate?.file) uploadBackgroundFile(certificate, 'assets/img/certificates')

    const objectDistributor = {
      slug,
      name,
      address,
      contact,
      email,
      phone,
      country,
      role,
      date_contract,
      params,
      redirectUrl,
      backgroundColor,
      logo: fileAppend?.name,
      certificate: certificate?.name,
      acquired: parseInt(acquired, 10)
    }

    const { match } = this.props
    if(match.params.id === undefined) {
      await distributorAction('ADD', objectDistributor)
    }
    else {
      await distributorAction('UPDATE', match.params.id, objectDistributor)
    }

    this.setState({ show: true })
  }

  hideModal = () => {
    this.setState({ show: false })
  }

  onChangeDate(date_contract) {
    this.setState({ date_contract })
  }

  onChangeBackgroundColor = (color) => {
    this.setState({ backgroundColor: color.hex });
  };

  render() {
    const { match } = this.props
    const { 
      file, 
      certificate,
      slug, 
      name, 
      address, 
      contact, 
      email, 
      phone, 
      country, 
      role, 
      date_contract, 
      params, 
      values, 
      show,
      redirectUrl,
      acquired,
      backgroundColor
    } = this.state

    this.state.edit = match.params.id
    return (
    <BaseQuestion name="Distributor" color="card badge-company">
      <form onSubmit={this.onSubmit}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Name: </label>
              <input
                type="text"
                name="name"
                required
                className="form-control"
                value={name}
                onChange={(e) => { 
                  this.onChange(e) 
                  this.setState({ slug: urlencode(e.target.value) })
                }}
              />
            </div>
          </div>          
          <div className="col-md-3">
            <div className="form-group">
              <label>Slug: </label>
              <input
                type="text"
                name="slug"
                required
                className="form-control"
                value={slug}
                onChange={(e) => { 
                  const elem = { ...e, target: { ...e.target, value: urlencode(e.target.value) }}
                  this.onChange(elem) 
                }}
              />
            </div>
          </div>
          <div className="col-md-1">
            <div className="form-group">
              <label>Acquired Credit: </label>
              <input
                type="number"
                name="acquired"
                required
                className="form-control"
                value={acquired}
                onChange={this.onChange}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <label>Date of Contract: </label>
              <div className="d-flex ">
                <DatePicker name="date_contract" className="form-control" selected={date_contract} onChange={this.onChangeDate}/>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <div className="form-group">
              <label>Main address: </label>
              <input type="text" name="address" required className="form-control" value={address} onChange={this.onChange} />
            </div>
          </div>
          <div className="col-sm">
            <div className="form-group">
              <label>Country: </label>
              <input type="text" name="country" required className="form-control" value={country} onChange={this.onChange} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>Name of main contact: </label>
              <input type="text" name="contact" required className="form-control" value={contact} onChange={this.onChange} />
            </div>
          </div>
          <div className="col-sm">
            <div className="form-group">
              <label>Role: </label>
              <input type="text" name="role" required className="form-control" value={role} onChange={this.onChange} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <div className="form-group">
              <label>Email: </label>
              <input type="text" name="email" required placeholder="email@email.com"  className="form-control" value={email} onChange={this.onChange} />
            </div>
          </div>
          <div className="col-sm">
            <div className="form-group">
              <label>Phone number: </label>
              <input type="text" name="phone" required placeholder="(22) 1234-0000"  className="form-control" value={phone} onChange={this.onChange} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <div className="form-group">
              <label>Redirect Url (let's empty to redirect default url): </label>
              <input
                type="text"
                name="redirectUrl"
                placeholder=""
                className="form-control"
                value={redirectUrl}
                onChange={this.onChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <div className="form-group">
              <label>Speaking passing<br/>score (50-100)%: </label>
              <input
                type="number"
                name="params.speaking"
                required
                placeholder="90"
                style={{ width: 150 }}
                className="form-control"
                value={params.speaking}
                onChange={(e) => {
                  this.onChangeSetState(e, { params: { ...params, speaking: e.target.value }})
                }}
                min="50"
                max="100"
                step="1"
              />
            </div>
          </div>
        </div>
        <br/>
        <div className="row">
          <div className="col-4">
            <Avatar
              file={file}
              id="file"
              addFiles={(e) => this.addFiles('file', e)}
              deleteFile={this.deleteFile}
              values={values}
            />

            <Avatar
              file={certificate}
              id="certificate"
              addFiles={(e) => this.addFiles('certificate', e)}
              deleteFile={this.deleteCertificate}
              values={values}
              label="Certificate"
            />
          </div>
          <div className="col-6">
            <SketchPicker
              color={backgroundColor}
              onChangeComplete={this.onChangeBackgroundColor}
            />
          </div>
        </div>
        <Button class="badge-azul" redirect={`${BASE_SLUG_URL}/distributor-list`} />
      </form>

      <Modal
        show={show}
        hideModal={this.hideModal}
        text ="Item added successfully!"
        redirect={`${BASE_SLUG_URL}/distributor-list`}
      />
    </BaseQuestion>              
  )}
}