import { Api as axios } from '../api'
import { getUserSession } from '../services/isLoggedIn'

const baseUrl = `distributors`

export const distributorAction = async (action, param, object) => {
  const user = await getUserSession('userAdmin')

  switch(action) {
    case 'GET':           
      const get = (user.profile !== 'ADMIN') 
        ? await axios.get(`${baseUrl}/get/${user.distributor._id}`)
        : await axios.get(`${baseUrl}/get`);
      return get.data;
    case 'GETCOMBO':      
      const getCombo = (user.profile !== 'ADMIN') 
      ? await axios.get(`${baseUrl}/getCombo/${user.distributor._id}`)
      : await axios.get(`${baseUrl}/getCombo`);
    return getCombo.data;
    case 'GETBYID': 
      const getID = await axios.get(`${baseUrl}/get/${param}`);
      return getID.data;    

    case 'UPDATE':
      const getupdate = await axios.post(`${baseUrl}/update/${param}`, object);
      return getupdate.data;
    
    case 'DELETE':
      const del = await axios.delete(`${baseUrl}/delete/${param}`);
      return del.data;
    
    case 'ADD':
      const add = await axios.post(`${baseUrl}/add`, param)
      return add.data;
    
    default:
      return;
  }
}
export const getDistributor = async() => {
  const distributor = await axios.get(apiURL() + 'distributors')
  return distributor.data
}