import React from 'react'
import { Link } from 'react-router-dom'

import page404 from './page404.svg'
import './Page404.css'

export default function Page404() {
  return (
    <div className="page404" justify="center">
      <div>
        <img src={page404} width="100%" alt="page404" />
      </div>
      <div>
        <h1>404</h1>
        <h2>UH OH! Você está perdido.</h2>
        <p>Está página que você está procurando parece não existir.
          Como você chegou até aqui é um mistério, mas você pode clicar abaixo e voltar para o início.
        </p>
        <Link to="/">Início</Link>
      </div>
    </div>
  )
}