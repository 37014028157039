import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Menuitem from './menu-item'
import { clearSession } from '../services/isLoggedIn'
import { menuAccessControl } from '../services/menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTachometerAlt, faChartBar, faWallet } from '@fortawesome/free-solid-svg-icons'
import { extractSlug } from '../services/slug'

const slug = extractSlug()

const MENU = [
  { 
    image: 'hearing.png',
    link: `question`,
    badge: 'badge-azul',
    name: 'Listening',
    divId: 'm_list',
    sub: [
      { link: `listening-a`, name: 'Listening A' },
      { link: `listening-b`, name: 'Listening B' }
    ],
  },
  { 
    image: 'book.png',
    link: `question`,
    badge: 'badge-orange',
    name: 'Grammar',
    divId: 'm_gram',
    sub: [
      { link: `grammar-a`, name: 'Grammar A' },
      { link: `grammar-b`, name: 'Grammar B' }
    ],
  },
  { 
    image: 'reading.png',
    link: `question`,
    badge: 'badge-azul',
    name: 'Reading',
    divId: 'm_read',
    sub: [
      { link: `reading-a`, name: 'Reading A' },
      { link: `reading-b`, name: 'Reading B' }
    ],
  },
  { 
    image: 'mic.png',
    link: `question`,
    badge: 'badge-pink',
    name: 'Speaking',
    divId: 'm_speak',
    sub: [
      { link: `speaking-a`, name: 'Speaking A' },
      { link: `speaking-b`, name: 'Speaking B' }
    ],
  },
  
  { 
    image: 'writing.png',
    link: `question`,
    badge: 'badge-azul',
    name: 'Writing',
    divId: 'm_writing',
    sub: [
      { link: `writing-a`, name: 'Writing A' },
      { link: `writing-b`, name: 'Writing B' }
    ],
  }
]
export default class Main extends Component { 
  constructor(props) {
    super(props)

    this.state = {
      profile: []
    }
  }
  
  componentDidMount() {
    document.body.className = 'adback'
    this.loadProfile()
  }

  loadProfile = async () => {
    const profile = await menuAccessControl()
    this.setState({ profile })   
  }

  render() {
    const { profile } = this.state
    
    const url = 'https://production-placementest.s3.amazonaws.com/assets/img/logos'
    const logo = `${url}/logo-${slug || 'conexia'}.png`

    return (
      <div>        
        <label className="tog font-45" htmlFor="toggle"> &#8801 </label>
        <input type="checkbox" id="toggle" />
        
        <div className="left side-menu">
          <div className="topbar-left">
            <img className="logosmall" src={logo} alt=""/>
          </div>
          <div className="" id="remove-scroll">        
            <ul className="metismenu">
              <div className="menu-title">Main</div>
              
              <div className="menu-b">
                <FontAwesomeIcon icon={faTachometerAlt} /> &nbsp;
                <Link to={`/${slug}/dash`}>Dashboard</Link>
              </div>

              <div className="menu-b">
                <FontAwesomeIcon icon={faChartBar} /> &nbsp;
                <Link to={`/${slug}/report`}>Report</Link>
              </div>
              <div className="menu-b">
                <FontAwesomeIcon icon={faChartBar} /> &nbsp;
                <Link to={`/${slug}/test`}>Evaluation in progress</Link>
              </div>
              <div className="menu-b">
                <FontAwesomeIcon icon={faWallet} /> &nbsp;
                <Link to={`/${slug}/finance`}>Finance</Link>
              </div>

              <Menuitem image="resgister.png" className="badge badge-azul" divId="register" name="Registers"> 
                <div className="m-t-15 d-block">
                  {profile === 'ADMIN' && (
                    <Link  className="adminsub" to={`/${slug}/distributor-list`}>Distributor</Link>
                  )}

                  {['ADMIN', 'DISTRIBUTOR_ADMIN'].includes(profile) && (
                    <Link className="adminsub" to={`/${slug}/school-list`}>School</Link>
                  )}

                  {['ADMIN', 'DISTRIBUTOR_ADMIN', 'MANAGER', 'SCHOOL_ADMIN'].includes(profile) && (
                    <>
                      <Link className="adminsub" to={`/${slug}/classroom-list`}>Classroom</Link>
                      <Link className="adminsub" to={`/${slug}/user-list`}>User</Link>
                    </>
                  )}

                  {profile === 'ADMIN' && (
                    <Link  className="adminsub" to={`/${slug}/importusers`}>Import</Link>
                  )}
                </div>
              </Menuitem>
              
              {profile === 'ADMIN' && (
                <>
                  <div className="menu-title">Flex Assessment Admin</div>
                  <div className="menu-b">
                    <img alt=""  src="../../img/teste.png" /> &nbsp;
                    <Link to={`/${slug}/question`}>Summary</Link> 
                  </div>
                  <div className="menu-title">Add Questions</div>

                  {MENU.map(menu => (
                    <Menuitem 
                      key={menu.divId}
                      image={menu.image}
                      link={`/${slug}/${menu.link}`}
                      className={`badge ${menu.badge}`}
                      name={menu.name}
                      divId={menu.divId}
                    >                 
                      <div className="d-block m-t-10">
                        {menu.sub.map((subMenu, index) => (
                          <Link
                            key={`submenu-${index}`}
                            className="adminsub"
                            to={`/${slug}/${subMenu.link}`}
                          >
                            {subMenu.name}
                          </Link>
                        ))}
                      </div>                                    
                    </Menuitem>  
                  ))}      
                </>                
              )}
                                            
              <br />
              <a onClick={() => clearSession('userAdmin')} className="menu-title logout"> Log out </a>
            </ul>        
            <div className="clearfix" />
          </div>      
        </div>    
      </div>
    )
  }
}
