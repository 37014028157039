import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { baseSlugURL, extractSlug } from '../services/slug'

const BASE_SLUG_URL = baseSlugURL()
const SLUG = extractSlug()
export default class ModalMessage extends Component {
  render() {
    const { redirect, hideModal } = this.props
    const redirectRoute = redirect ? redirect.replace(BASE_SLUG_URL, '/' + SLUG) : null

    return (
      <div>
        <Modal show={this.props.show} onHide={this.props.hideModal}>
          <Modal.Header closeButton>
            <h5 className="modal-title m-t-0 text-black" id="exampleModalLongTitle"> Admin </h5>
          </Modal.Header>
          <Modal.Body>
            <p>{this.props.text}</p>
          </Modal.Body>
          <Modal.Footer>
            {
              redirectRoute ?
              <Link className="btn btn-secondary waves-effect waves-light" to={redirectRoute} >
                Close
              </Link>
              :
              <button className="btn btn-secondary waves-effect waves-light" onClick={hideModal}>
                Close
              </button>
            }
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}
