import React, { useEffect } from 'react'
import Header from './header'
import Main from './main'
import { getUserSession } from '../services/isLoggedIn'

const user = getUserSession('userAdmin')
 
function BaseQuestion(props) {
    const { backgroundColor = '#36487d' } = user?.distributor || { backgroundColor: '#36487d' }
    const color = (!backgroundColor && backgroundColor !== '') ? backgroundColor : '#36487d'

    return (    
        <div> 
            <Main />  
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xl-9">
                                    <Header class={props.color} style={{ backgroundColor: color }} text={props.name} />                            
                                    <div className="card w">
                                        <div className="card-body"> 
                                            {props.children}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default BaseQuestion