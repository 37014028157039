import ReactS3 from 'aws-s3'
import { adminConfig } from '../api'

export const uploadBackground = (props, dir) => {
  const { file } = props.state
  if (!file.file || file.file === '') return
  if (!dir) return    
  const S3Client = new ReactS3(adminConfig(dir).config)
  S3Client
    .uploadFile(file.file)
    .then(() => { 'uploaded!' })
    .catch(err => { console.error(err) })  
}

export const uploadBackgroundFile = (file, dir) => {
  if (!file.file || file.file === '') return
  if (!dir) return    
  const S3Client = new ReactS3(adminConfig(dir).config)

  const [_, extension] = (file.file?.name || '').split('.')

  S3Client
    .uploadFile(file.file, (file.name || '').replace(`.${extension}`, ''))
    .then(() => { 'uploaded!' })
    .catch(err => { console.error(err) })  
}