// eslint-disable-next-line no-undef
const { FRONTEND_URL } = process.env;

export const USERS_ROLE = [
  'STUDENT', 
  'COACH', 
  'SCHOOL_ADMIN', 
  'MANAGER', 
  'DISTRIBUTOR_ADMIN', 
  'ADMIN'
]

export const extractSlug = () => {
  const { pathname: currentPage } = window.location
  const [ _, slug ] = (currentPage || '').split('/')
  return slug
}

export const baseURL = () => {
  const url = FRONTEND_URL.replace(/%/gi, '')
  return url
}

export const baseSlugURL = () => {
  const url = baseURL();
  const slug = extractSlug()  
  return `${url}/${slug}`
}
