import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

function EditButton({ data, onClick }) {
  return (
    <div>
      <button
        className="btn btn-link element-hover"
        style={{ boxShadow: 'none', textDecoration: 'none' }}
        onClick={onClick}
      >
        {data} <FontAwesomeIcon icon={faEdit} color="#fac" />
      </button>                    
    </div>
  )
}

export default EditButton