import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { updateQuestion } from '../../services/report'

function ModalUpdateAnswer({ testId, data, test, show, onClose, onLoadData }) {
  if (!data || !testId) return <></>

  const [question, setQuestion] = useState(data)
  
  const onClick = async () => {
    const response = await updateQuestion(testId, test?.testType, { ...question, hasEdited: true })
    if (response.data === 'updated!') {
      if (onClose) onClose()
      if (onLoadData) onLoadData()
    }
  }
  
  const onChange = ({ target }) => {
    const iscorrect = target.value === 'true'
    setQuestion({ ...question, iscorrect })
  }

  return (
    <Modal show={show} onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Answer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-sm">
            <div className="form-group">
              <label>Answer is: </label>
              <select
                name="iscorrect"
                required
                className="form-control"
                value={question?.iscorrect || false}
                onChange={onChange}
              >
                <option value={true}>Correct</option>
                <option value={false}>Incorret</option>
              </select>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button type="button" variant="primary" onClick={onClick}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalUpdateAnswer