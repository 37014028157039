import React, { Component } from 'react'
import Modal from "./modal"
import Button from "./button"
import { distributorAction }  from '../services/distributor'
import { userAction } from '../services/user'
import { schoolAction } from '../services/school'
import { baseSlugURL, USERS_ROLE } from '../services/slug'
import { getCurrentUser } from '../services/isLoggedIn'
import BaseQuestion from './base-question'

const BASE_SLUG_URL = baseSlugURL()
export default class User extends Component {

  constructor(props) {
    super(props)
    
    this.state = {
      name: '',
      contact: '',
      email: '',
      phone: '',
      address: '',
      country: '',
      profile: 'STUDENT', 
      distributors:[],
      schools: [],
      password: '',
      block: false,
      show: false,
      modalUserNotAvailable: false,
    }   
  }

  componentDidMount() {
    this.fetchData()
    this.loadData()
    this.currentUser()
  }

  currentUser = async () => {
    const current = getCurrentUser()
    this.setState({ current })
  }

  fetchData = async () => {
    const schools = await schoolAction('GETCOMBO')
    this.setState ({ schools })

    const distributors = await distributorAction('GETCOMBO')
    this.setState({ distributors })
  }

  loadData = async () => {
    const { match } = this.props
    const { id } = match.params

    if(id !== undefined) {
      const user = await userAction('GETBYID', id)           
      this.setState({
        active: user.active,
        createdAt: user.createdAt,
        email: user.email,
        id: user.id,
        importID: user.importID,
        level: user.level,
        login: user.login,
        name: user.name,
        password: user.password,
        profile: user.profile,
        school: user?.school?.name,
        updatedAt: user.updatedAt,
        distributor: user.distributor.name,
        block: user.block       
      })
    }
  }

  onChange = (e) => {
    e.preventDefault()
    this.setState({ [e.target.name]: e.target.value })
  }

  onCheck = () => this.setState({ block: !this.state.block })

  onSubmit = async(e) => {
    e.preventDefault()

    const { match } = this.props
    const { id } = match.params
    const { 
      name,
      profile,
      schools,
      school,
      email,
      phone,
      distributors,
      distributor,
      password,
      login,
      import_id: importID,
      block,
    } = this.state

    const objectUser = {
      school: school && schools.find(f=> f.name === school)._id, 
      distributor: distributor && distributors.find(f=> f.name === distributor)._id, 
      name,
      profile, 
      email,
      phone,
      password,
      login,
      importID,
      block
    }

    let response
    if(!id) {
      response = await userAction('ADD', objectUser)
    } else {
      response = await userAction('UPDATE',  id, objectUser)
    }

    if(response === 'Error to create user') {
      this.setState({ modalUserNotAvailable: true })
      return
    }
    this.setState({ show: true })
  }

  hideModal = () => this.setState({ show: false })

  render() {
    const { profile, current } = this.state
    const indexOf = USERS_ROLE.findIndex(data => data === current?.profile)
    const users = USERS_ROLE.filter((_, index) => index <= indexOf)
    const hiddenSchool = !['ADMIN', 'DISTRIBUTOR_ADMIN', 'MANAGER'].includes(profile)
    const hiddenStudent = ['STUDENT'].includes(profile)

    return (
      <BaseQuestion name="User" color="card badge-company">
        <form onSubmit={this.onSubmit}>
          <div className="row">           
            <div className="col-sm-8">
              <div className="form-group"> 
                <label>Name: </label> 
                <input type="text" name="name" required className="form-control" value={this.state.name} onChange={this.onChange} />
              </div>
            </div> 
            <div className="col-sm-4">
              <div className="form-group"> 
                <label>Profile</label>
                <select 
                  ref="user" 
                  name="profile"
                  required 
                  className="form-control" 
                  value={this.state.profile} 
                  onChange={this.onChange} 
                >
                  <option value="">Choose any</option>
                  {users.map((role, key) => (
                    <option key={key} value={role}> {role}</option>
                  ))}
                </select>
              </div>
            </div>                                           
          </div>
          <div className="row"> 
            <div className="col-sm">
              <div className="form-group"> 
                <label>Distributor: </label>
                <select ref="user" name="distributor"  required className="form-control" value={this.state.distributor} onChange={this.onChange} >
                  <option value="">Choose any</option>
                  {this.state.distributors.map((distributor, key) => {                            
                    return (<option key={key} value={distributor.name}>{distributor.name} </option>)
                  })}
                </select>
              </div>
            </div>                                                
            {hiddenSchool && (
              <div className="col-sm">
                <div className="form-group"> 
                  <label>School: </label>
                    <select ref="user" name="school" required className="form-control" value={this.state.school} onChange={this.onChange} >
                      <option value="">Choose any</option>
                        {this.state.schools.map((school, key) => {
                        return (<option key={key} value={school.name}> {school.name} </option>)
                        })}
                    </select> 
                </div> 
              </div> 
            )}
            <div className="col-sm">                      
              <div className="form-group"> 
                <label>Phone number: </label>
                <input type="text" name="phone" placeholder="(22) 1234-0000" className="form-control" value={this.state.phone} onChange={this.onChange} />
              </div>                       
            </div>
            </div>   
            <div className="row"> 
              <div className="col-sm">
                <div className="form-group"> 
                  <label>Login </label>
                  <input type="text" name="login" required  className="form-control" value={this.state.login} onChange={this.onChange} />
                </div>
              </div>          
              <div className="col-sm">
                <div className="form-group"> 
                  <label>Email: </label>
                  <input type="text" name="email" required placeholder="email@email.com"  className="form-control" value={this.state.email} onChange={this.onChange} />
                </div>
              </div>
              <div className="col-sm">
                <div className="form-group"> 
                  <label>Password: </label>
                  <input type="password"  required name="password" className="form-control" value={this.state.password} onChange={this.onChange} />
                </div>
              </div> 
            </div>  

            {hiddenStudent && (
              <div className="row"> 
                <div className="col-md">
                  <div className="form-check">                          
                    <input className="form-check-input" name="block" type="checkbox" checked={this.state.block === true ? "checked" : ""} id="flexCheckDefault" onClick={this.onCheck}/>
                    <label className="form-check-label" htmlFor="flexCheckDefault">Block PT </label>
                  </div>
                </div>                                     
              </div>       
            )}
          <Button className="badge-azul" redirect={`${BASE_SLUG_URL}/user-list`} />
        </form>
        <Modal show={this.state.show} hideModal={this.hideModal} text ="Item added successfully!" redirect={`${BASE_SLUG_URL}/user-list`}></Modal>
        <Modal 
          show={this.state.modalUserNotAvailable}
          text={`this username "${this.state.login}" is already used by someone else`}
          hideModal={() => this.setState({ modalUserNotAvailable: false })}
        />                 
      </BaseQuestion>
  )}
}