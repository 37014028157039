import React, { Component } from 'react';
import ReactS3 from 'aws-s3';
import Question from './question';
import Audio from './audio';
import Images from './images';
import Modal from "./modal";
import Button from "./button";
import BaseQuestion from "./base-question"
import { adminConfig, Api as axios } from '../api';
import { baseSlugURL } from '../services/slug'

const BASE_SLUG_URL = baseSlugURL()
const S3Client = new ReactS3(adminConfig('la').config);

export default class ListeningA extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      order: 0,
      choices: [], 
      files: [],
      values: '',
      audio: '',
      show: false,                
      timer: 45,
      level: 'A1',
      ptmodel: 'Academic',
      modelname: 'Listening-A',
      grammar: 'To be present tense',
      active: true
    }
  }

  addFiles = (e) => {
    let { files } = this.state
    for(let i=0; i < e.target.files.length; i++ ) {
      if(files.filter(f => f.name === e.target.files[i].name).length === 0){
        files.push({ 
          blob: URL.createObjectURL(e.target.files[i]), 
          name: e.target.files[i].name,
          file: e.target.files[i]
        })
      }
    }
    this.setState({ files })
  }

  addAudio = (e) => {
    const [file] = e.target.files;
    const blob = URL.createObjectURL(file);
    const audio = {
      name: file.name,
      file,
      blob
    };
    this.setState({ audio });
  };

  deleteFile = (e) => {
    const files = this.state.files.filter(f => f.name !== e)
    this.setState({ files })
  }

  componentDidMount() {
    if(this.state.edit !== undefined) {
        axios.get('questions/' + this.props.match.params.id)
            .then(response => {   
              const [files] = response.data.choices
              const newfiles = []

              for(let i=0; i < files.optionlist.length; i++ ) {
                let img = adminConfig().root + "/la/" + files.optionlist[i]
                newfiles.push({ 
                  blob: img, 
                  name: files.optionlist[i],
                  file: null
                })
              }

              const audio = {
                blob: adminConfig().root + "/la/" + response.data.sentence,
                name: response.data.sentence,
                file: null
              }
                                                                  
              this.setState({
                ptmodel: response.data.ptmodel,
                name: response.data.name,
                order: response.data.order,
                modelname: response.data.modelname,
                level: response.data.level,
                timer: response.data.timer,
                sentence_type: response.data.sentence_type,
                sentence: response.data.sentence,
                choices: response.data.choices,
                grammar: response.data.grammar,
                files: newfiles,
                audio: audio,
                values: response.data.choices[0].word,
                active: response.data.active
              })
            })
            .catch(function (error) {console.error(error);})
        }
  }

  onSubmit = (e) => {
    e.preventDefault();
 
    S3Client
      .uploadFile(this.state.audio.file)
      .then(console.info)
      .catch(console.error);
    
    let optionlist = []
    for (let i = 0; i < this.state.files.length; i++) {
      if (this.state.files[i].file) {
        S3Client.uploadFile(this.state.files[i].file, this.state.files[i].name)
          .then( (data) => { console.info(data) })
          .catch( (err) => { console.error(err) })
      }
      optionlist.push(this.state.files[i].name);
    }
    let choices = [{ word: this.state.values, optionlist: optionlist}]    
    
    const question = {
      ptmodel: this.state.ptmodel,
      name: this.state.name,
      order: this.state.order,
      modelname: this.state.modelname,
      level: this.state.level,
      timer: this.state.timer,
      sentence_type: this.state.sentence_type,
      sentence: this.state.audio.name,
      choices: choices,
      grammar: this.state.grammar,
      chuncks: '',
      active: this.state.active
    };
    
    if(this.props.match.params.id === undefined) {
      axios
        .post('questions/add', question)
        .then(res => console.info(res.data))
        .catch((error) => {console.error(error)})
    } else {
      axios
        .post('questions/update/'+ this.props.match.params.id, question)
        .then(res => console.info(res.data))
        .catch((error) => {console.error(error);})
    }
    this.setState({ show: true });
}

onChange = (e) => {
  if (e.target.name === 'active') {
    this.setState({ active: e.target.checked });
    return;
  }

  e.preventDefault();
  this.setState({ [e.target.name]: e.target.value });
}

  onChangeValues = (values) => this.setState({ values })

  hideModal = () => this.setState({ show: false })

  render() {
    const { 
      ptmodel, 
      name, 
      order, 
      level, 
      timer, 
      modelname, 
      grammar, 
      files, 
      audio,
      values,
      show,
      active
    } = this.state

    this.state.edit = this.props.match.params.id;
    return ( 
      <BaseQuestion name="Listening A" color="card badge-pink">
        <form onSubmit={this.onSubmit}>
          <Question 
            ptmodel={ptmodel} 
            active={active}
            name={name} 
            order={order} 
            level={level} 
            timer={timer} 
            modelname={modelname} 
            grammar={grammar} 
            onChange={this.onChange}
          />

          <Audio audio={audio} addAudio={this.addAudio} />

          <Images 
            files={files} 
            addFiles={this.addFiles} 
            deleteFile={this.deleteFile} 
            values={values} 
            onChangeValues={this.onChangeValues} 
          />

          <Button 
            class="badge-pink" 
            redirect={`${BASE_SLUG_URL}/question`} 
          />

          <Modal 
            show={show} 
            hideModal={this.hideModal} 
            text="Item added successfully!" 
            redirect={`${BASE_SLUG_URL}/question`}
          />                
        </form>
      </BaseQuestion>        
    )
  }
}