import axios from 'axios'
import { Api, conexiaAPI } from '../api'
import { addSchool } from '../services/school'
import { addUser } from '../services/user'
import { addClassroom } from '../services/classroom'

export const importData = async(distributorID, tenantID) => {
  const data = await connectToApi(distributorID, tenantID)

  for(let t=0; t < data.response.length; t++) {
    let tenant = data.response[t]
    let importID = tenant.importID
    tenant.profiles.map(m => {
      m.type = (
        m.type === 'teacher' ? 'COACH' : 
          (m.type === 'student' ? 'STUDENT' : 
          (m.type === 'manager' ? 'MANAGER' : m.type )))
    })
            
    let users = tenant.users
    
    console.log('tenant' + tenant.tenantName)
      
    // 1. save schools
    for(let i=0; i < tenant.schools.length; i++) {
  
      let periods = tenant.schools[i].structures.filter(f=> f.type === 'period')
      let segments = tenant.schools[i].structures.filter(f=> f.type === 'segment')
      let classrooms = tenant.schools[i].groups.filter(f=> f.type === 'class')
           
      let school_id = await addSchool(tenant.schools[i] , distributorID, importID)
  
      console.log('school' + tenant.schools[i].name)

      //classrooms
      for(let c=0; c < classrooms.length; c++) {         
        let classroom = classrooms[c]
        let user_ids = [] 
        let classroom_ids = []
  
        console.log('users')

        //A. Insert Teachers + Get users
        for (let u=0; u < classroom.users.length; u++) {
          let profile = tenant.profiles.filter(f=> f.id === classroom.users[u].profileId)[0].type
          let user = users.filter(f=> f.id === classroom.users[u].userId)[0]                 
          const user_id = await addUser(user, profile, distributorID, school_id, importID)  
          user_ids = user_ids.concat(user_id)            
          console.log(u + ' user ' + user.name)
        }
        
        //B. Insert class
        classroom.class_period = classroom.structures.find(f => f.type === 'period')
        classroom.class_grade  = classroom.structures.filter(f => f.type === 'grade')
        
        const classroom_id = await addClassroom(classroom, periods, school_id, user_ids, importID)
        classroom_ids = classroom_ids.concat(classroom_id)
                 
        console.log(user_ids)
        console.log(classroom_ids)
      }    
    }
  }
}

export const getTenants = async() => {
  const api = conexiaAPI()
  //1. token
  const dataConnection = { 
    clientid: api.clientID, 
    clientsecret: api.clientSecret 
  }
  const token = await axios.post(api.token, dataConnection, { 
    headers: { 'content-type': 'text/json' }
  })
  
  //2. tenant
  const tenantData = await axios.get(api.tenant, { 
    headers: { 'Authorization': 'Bearer '+ token.data.token }
  })
  return tenantData.data.payload
}

const connectToApi = async (distributor_id, tenant_id) => {
  const api = conexiaAPI()

  let param = {
    token: '',
    sync: null,
    response: null,
  }
  
  //1. token
  const dataConnection = { 
    clientid: api.clientID, 
    clientsecret: api.clientSecret 
  }
  const token = await axios.post(api.token, dataConnection, {
    headers: { 'content-type': 'text/json' }
  })
  param.token = token.data.token

  //2. tenant
  const tenantData = await axios.get(api.tenant, { 
    headers: { 'Authorization': 'Bearer '+ param.token }
  })
  let tenants = tenantData.data.payload//[0].id 
  
  if (tenant_id && tenant_id !== '') {
    tenants = tenants.filter(f => f.id === tenant_id)
  }
  param.sync = tenantData.data.header

  //3. structure
  let listTenants = []
  for(let i = 0; i < tenants.length; i++) {
    const users = await axios.get(api.full + '?tenantId=' + tenants[i].id, {
      headers: { 'Authorization': 'Bearer '+ param.token } 
    })
    const item = users.data.payload;
    const import_id = await addImport(item, distributor_id)
    item.importID = import_id
    listTenants = listTenants.concat(item)  
    console.log(item.tenantName, item.importID)    
    //4. import save data
  }
  param.response = listTenants
  return param
}

export const addImport = async(information, distributor) => {
  const imports = {
    distributor,    
    information,
    date: Date.now(),
    status: ''
  }

  const import_id = await Api.post('imports/integration/add', imports)
  return import_id.data
}

export const closeAPI = async(token, syncId, status) =>  {  
  const data = {
    syncId,
    status
  }

  const object = await axios.put(conexiaAPI().status, data,  { 
    headers: { Authorization: 'Bearer '+ token } 
  })
  return object
}



