import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { userAction } from '../services/user'
import { schoolAction } from '../services/school'
import { distributorAction } from '../services/distributor'
import { load } from './load'
import BaseQuestion from './base-question'
import ReactPaginate from 'react-paginate'
import { extractSlug, USERS_ROLE } from '../services/slug'
import { getCurrentUser } from '../services/isLoggedIn'

const SLUG_URL = extractSlug()

export default class UserList extends Component {
  constructor(props) { 
    super(props)
    this.state = {
      listobj: [],
      schools:[],
      distributors:[],
      distributor: 'All',
      school: 'All',
      user: '',
      profile: 'All',
      orderBy: 'name',
      sortBy: 'DESC',
      currentPage: 0,
      current: null,
    }  
  }

  componentDidMount() {
    this.fetchData()
    this.currentUser();
  }

  currentUser = async () => {
    const current = getCurrentUser()
    this.setState({ current })
  }
  
  fetchData = async () => {
    load()
  
    const distributors = await distributorAction('GETCOMBO')
    this.setState({ distributors })  
    const schools = await schoolAction('GETCOMBO')
    this.setState({ schools })  
    
    this.fetchUsers()
  
    load()    
  }

  fetchUsers = async () => {
    const { distributor, profile, school, user, orderBy, sortBy, currentPage } = this.state
    const query = {
      distributor,
      school,
      profile,
      q: user,
      currentPage,
      orderBy,
      sortBy
    }

    const listobj = await userAction('GET_WITH_QUERY', query)
    this.setState({ listobj, dataset: listobj })
  }

  onPageClick = async (data) => {
    const { selected: currentPage } = data
    this.setState({ currentPage: currentPage }, ()=> this.fetchUsers())
  }

  onChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value })
  }

  delete = async(id) => {
    await userAction('DELETE', id)
    this.setState({ listobj: this.state.listobj.filter(el => el._id !== id) })
  }

  filterGrid = () => {
    this.setState({ currentPage: 0 }, ()=> this.fetchUsers())
  }

  render() {
    const { 
      distributors, 
      distributor, 
      // users, 
      user,
      schools, 
      school,
      profile,
      listobj,
      current
    } = this.state

    const indexOf = USERS_ROLE.findIndex(data => data === current?.profile);
    const users = USERS_ROLE.filter((_, index) => index <= indexOf);

    return ( 
      <BaseQuestion name="User" color="card badge-company">
        <div className="row">
          <div className="col-sm ">
            <div className="form-group">
              <label>Distributor </label>
              <select ref="user" name="distributor" required className="form-control" value={distributor} onChange={this.onChange} >
              <option value="All">All</option>
                {distributors.map((item, key) => {
                  return (
                  <option key={key} value={item._id}>
                    {item.name}
                  </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-sm ">
            <div className="form-group">
              <label>School </label>
              <select ref="user" name="school" require className="form-control" value={school} onChange={this.onChange} >
                <option value="All">All</option>
                {schools.map((school, key) => (
                  <option key={key} value={school._id}> {school.name} </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-sm ">
            <div className="form-group">
              <label>Search </label>
                <input type="text" className="form-control" name="user" value={user} onChange={this.onChange} />
            </div>
          </div>
          <div className="col-sm ">
            <div className="form-group">
              <label>Profile </label>
              <select ref="user" name="profile"  required className="form-control" value={profile} onChange={this.onChange} >
                <option value="All">All</option>
                {users.map((role, key) => (
                  <option key={key} value={role}> {role}</option>
                ))}
              </select>
            </div>
          </div>                    
          <div className="col-sm">
            <div className="form-group m-t-30 ">
              <a className="btn btn-primary waves-effect waves-light" href="#" onClick={this.filterGrid}>
                  Search
              </a>
              </div>
          </div>
          <div className="col m-b-20">
            <div className="float-right m-t-30 m-r-15">
              <Link className="btn btn-secondary waves-effect waves-light" to={`/${SLUG_URL}/user`}>New User</Link>                                                                                                
            </div>   
          </div>                    
        </div>  
        <div className="table-responsive b-0">
          <div className="col ">
            <table className="table table-hover mb-0">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Login</th>
                  <th>Email</th>
                  <th>Profile</th>
                  <th>Distributor</th>
                  <th>School</th>
                  <th></th>
                </tr>
              </thead>
            <tbody> 
              {listobj.map((obj, key) => {
                return (
                <tr key={key}>
                  <td>{obj.name}</td>
                  <td>{obj.login}</td>
                  <td>{obj.email} </td>
                  <td>{obj.profile} </td>
                  <td>{obj.distributor.name}</td>
                  <td>{obj?.school?.name}</td>
                  <td>
                    <div className="d-flex justify-content-end">
                    <Link className="btn btn-primary btn-sm" to={`/${SLUG_URL}/edit-user/${obj._id}`}>Edit</Link> &nbsp;
                      <a className="btn  btn-sm btnred" href="#" onClick={() => { this.delete(obj._id);}}>Delete</a>
                    </div>
                  </td>
                </tr>)
              })}                       
            </tbody>  
            </table>
          </div>
          <div id="react-paginate">
            <ReactPaginate
              pageCount={30}
              pageRangeDisplayed={10}
              onPageChange={this.onPageClick}
            />
          </div>
        </div>                                                  
      </BaseQuestion>
  )}
}