import React from 'react'

function Card({ title, children }) {
  return (
    <div className="cards__item">
      <div className="card">
        <div className="card__content">
          {title && (<div className="card__title">{title}</div>)}
          <div className="card__text">{children}</div>
        </div>
      </div>
    </div>
  )
}

// <button className="btn btn--block btn-primary">Button</button>
export default Card