import { Api as axios } from '../api'

const baseURL = `test`

export const testAction = async(action, param, object) => {
  switch(action) {
    case 'GETTESTPROFILE':
      const testProfile = await axios.post(`${baseURL}/getTestProfile/${param}`, object)     
      return testProfile.data
    default:
      return
  }
}
