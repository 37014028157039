import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Api as axios } from '../api';
import { load } from './load';
import BaseQuestion from './base-question';
import ReactPaginate from 'react-paginate';
import queryString from 'query-string';
import { extractSlug } from '../services/slug'

const SLUG_URL = extractSlug()
export default class QuestionList extends Component {

constructor(props) {
  super(props);
  this.delete = this.delete.bind(this);
  this.state = {
    listobj: [],
    grid: [],
    ptmodels: [],
    levels: [],
    modelsname: [],
    modelname: 'All',
    ptmodel: 'All',
    level: 'All',
    currentPage: 0
  };
}

onChange = (e) => {
  e.preventDefault()
  const { name, value } = e.target
  this.setState({ [name]: value });   
}

componentDidMount() {
  load()
  this.setState({ 
    ptmodels: ['All', 'Academic', 'Kids'],
    levels: ['All', 'A1', 'A2', 'B1', 'B2', 'C1', 'C2' ],
    modelsname: ['All', 'Grammar-A', 'Grammar-B', 'Reading-A', 'Reading-B', 'Listening-A', 'Listening-B', 'Writing-A', 'Writing-B', 'Speaking-A', 'Speaking-B']
  });
  
  this.loadData()       
}

loadData = () => {
  const { ptmodel, modelname, level, currentPage }  = this.state;
  const qs = {
    currentPage,
    ptmodel,
    modelname,
    level
  }
  const url = `questions/getSummaryQuestions/x?${queryString.stringify(qs)}`
  axios.get(url)
    .then(response => {
      this.setState({ listobj: response.data.question });
      this.setState({ grid: response.data.question });
      load()
    })
    .catch((error) => {console.error(error);})
}

onPageClick = async (data) => {
  const { selected: currentPage } = data
  this.setState({ currentPage: currentPage }, ()=> this.loadData())
}

delete = id => {
  const { grid: gridOriginal, listobj: listobjOriginal } = this.state      
  const grid = gridOriginal.filter(el => el._id !== id)
  const listobj = listobjOriginal.filter(el => el._id !== id)
  this.setState({ grid, listobj }, () => {    
    axios.delete('questions/' + id)
  })
}

filterGrid = () => {
  this.onPageClick({ selected: 0 });
}

render() {
  const { grid } = this.state
  return (  
  <BaseQuestion name="Summary of Questions" color="card badge-company" >
      <div className="row">                  
        <div className="col-sm">
          <div className="form-group"> 
            <label>Flex Assessment Type </label>
            <select ref="userInput" name="ptmodel" required className="form-control" value={this.state.ptmodel} onChange={this.onChange} >
              {this.state.ptmodels.map(function(ptmodel) { return <option key={ptmodel} value={ptmodel}>{ptmodel} </option>;})}
            </select>
          </div>
        </div>
        <div className="col-sm">
          <div className="form-group"> 
            <label>Question Type </label>
            <select ref="userInput" name="modelname" required className="form-control" value={this.state.modelname} onChange={this.onChange} >
              {this.state.modelsname.map(function(modelname) { return <option key={modelname} value={modelname}>{modelname} </option>;})}
            </select>
          </div>
        </div>
        <div className="col-sm">
          <div className="form-group"> 
            <label>Level </label>
            <select ref="userInput" name="level" required className="form-control" value={this.state.level} onChange={this.onChange} >
              {this.state.levels.map(function(level) { return <option key={level} value={level}>{level} </option>;})}
            </select>
          </div>
        </div>
        <div className="col-sm">
          <div className="form-group m-t-15 m-r-15 float-right">                                           
            <div className="btn btn-secondary waves-effect waves-light" href="#" onClick={this.filterGrid}>Search</div>                  
          </div>
        </div>
      </div>
      <div className="table-responsive b-0">
        <div className="col">
          <table className="table table-hover mb-0">
          <thead>
            <tr>
            <th>PT Type</th>
            <th>Question Type</th>
            <th>Question ID</th>
            <th>Level</th>
            <th>Order</th>
            <th></th>
            </tr>
          </thead>
          <tbody>
            {grid?.map((obj, key) => {
              return (
              <tr key={'qts'+key}>
                <td>{obj.ptmodel}</td>
                <td>{obj.modelname}</td>
                <td>{obj.name} </td>
                <td>{obj.level}</td>
                <td>{obj.order}</td>
                <td>
                  <div className="d-flex justify-content-end">
                    <Link className="btn btn-primary btn-sm" to={"/" + SLUG_URL + "/edit-" + obj.modelname + "/" + obj._id}>Edit</Link> &nbsp;
                    <a className="btn  btn-sm btnred" href="#" onClick={() => { this.delete(obj._id);}}>Delete</a>
                  </div>
                </td>
              </tr>)
            })}
          </tbody>
          </table>
          <div id="react-paginate">
            <ReactPaginate
              pageCount={30}
              pageRangeDisplayed={10}
              onPageChange={this.onPageClick}
            />
          </div>
        </div>
      </div>
    </BaseQuestion>        
  )}
}
