import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import App from './App'

if (process.env.LOGROCKET_TOKEN) {
  LogRocket.init(process.env.LOGROCKET_TOKEN)
  setupLogRocketReact(LogRocket)
}

ReactDOM.render(
  <BrowserRouter>
    <App />, 
  </BrowserRouter>,
  document.getElementById('root')
)